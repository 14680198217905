import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7d8c0250"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "scrollContainer"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "avatar"
};
const _hoisted_4 = {
  class: "others"
};
const _hoisted_5 = {
  class: "oneRow"
};
const _hoisted_6 = {
  class: "oneRow"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Avatar = _resolveComponent("Avatar");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_ElImage = _resolveComponent("ElImage");
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patientList, item => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      onClick: $event => _ctx.handleChoosePatient(item),
      class: "onePatient"
    }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_icon, {
      size: "30px"
    }, {
      default: _withCtx(() => [_createVNode(_component_Avatar)]),
      _: 1
    })]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, _toDisplayString(item.name), 1), item.sex_icon ? (_openBlock(), _createBlock(_component_ElImage, {
      key: 0,
      src: _ctx.staticBase + item.sex_icon,
      class: "sexIcon"
    }, null, 8, ["src"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, _toDisplayString(item.card_number), 1), _createTextVNode("  |   "), _createElementVNode("div", null, [_createVNode(_component_vab_icon, {
      icon: "phone-fill"
    }), _createTextVNode(" " + _toDisplayString(item.mobile), 1)])])])], 8, _hoisted_2)), [[_directive_infinite_scroll, _ctx.handleinfiniteScroll]]);
  }), 256))]);
}