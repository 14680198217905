import { defineComponent } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message-box/style/index';
import 'element-plus/es/components/message/style/index';
import "core-js/modules/es.array.push.js";
import BasicComp from '@/views/Basic.vue';
import { encryption } from '@/utils/validate';
import { getPatientDetail, getRelationshipList, getRelationshipTree, delRelationship } from '@/api/patient';
import AddForm from './add.vue';
import { Plus, ArrowUp, ArrowDown } from '@element-plus/icons-vue';
import { getSexName } from '../../utils';
export default defineComponent({
  name: 'relationshipList',
  extends: BasicComp,
  components: {
    AddForm
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  watch: {
    showType: {
      handler(newValue, oldValue) {
        if (newValue == 'list') {
          this.getRelationshipList();
        } else {
          this.getRelationshipTree();
        }
      }
    },
    id: {
      handler(newValue, oldValue) {
        if (this.showType == 'list') {
          this.getRelationshipList();
        } else {
          this.getRelationshipTree();
        }
      }
    }
  },
  data() {
    return {
      showType: 'list',
      table: {
        data: [],
        column: [{
          label: '关系',
          prop: 'relational_tag_name'
        }, {
          label: '亲友',
          prop: 'name'
        }, {
          label: '病历号',
          prop: 'card_number'
        }, {
          label: '性别',
          prop: 'sex'
        }, {
          label: '手机号',
          prop: 'mobile'
        }, {
          label: '备注',
          prop: 'remarks'
        }, {
          label: '操作',
          prop: ''
        }]
      },
      showAddForm: false,
      dataInfo: null,
      Plus,
      ArrowUp,
      ArrowDown,
      currentRow: null,
      relationshipTree: [],
      relationshipData: {},
      horizontal: false,
      collapsable: true,
      onlyOneNode: true,
      cloneNodeDrag: true,
      expandAll: true,
      style: {
        background: "#fff",
        color: "#5e6d82"
      },
      patientInfo: null
    };
  },
  created() {
    this.getRelationshipList();
  },
  methods: {
    getSexName(sex) {
      return getSexName(sex) || '未知';
    },
    async getRelationshipList() {
      const {
        code,
        data
      } = await getRelationshipList(encryption({
        patient_id: this.id
      }));
      this.table.data = data || [];
    },
    async getRelationshipTree() {
      const {
        code,
        data: relationshipTree
      } = await getRelationshipTree(encryption({
        patient_id: this.id
      }));
      this.relationshipTree = relationshipTree || [];
      const {
        data: patientInfo
      } = await getPatientDetail(encryption({
        id: this.id
      }));
      this.patientInfo = patientInfo;
      this.relationshipData = {
        id: 0,
        label: patientInfo.name,
        avatar: patientInfo.avatar,
        children: []
      };
      relationshipTree.forEach(relationship => {
        const children = [];
        relationship.items?.forEach(item => {
          children.push({
            ...item,
            id: item.relevance_id,
            label: item.name,
            avatar: item.avatar
          });
        });
        this.relationshipData.children.push({
          pid: 0,
          id: relationship.name,
          label: relationship.name,
          children: children
        });
      });
    },
    addData() {
      this.dataInfo = null;
      this.showAddForm = true;
    },
    handleEdit(row) {
      this.dataInfo = row;
      this.showAddForm = true;
    },
    async handleDelete(row) {
      ElMessageBox.confirm(this.delConfirmMsg, null, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {
          code,
          data
        } = await delRelationship(encryption({
          id: row.id
        }));
        if (code == 200) {
          ElMessage({
            message: this.delSuccessMsg,
            type: 'success'
          });
          this.getRelationshipList();
        }
      });
    },
    handleCancelCallback() {
      this.dataInfo = null;
      this.showAddForm = false;
    },
    handleChangeType(type) {
      this.showType = type;
    },
    handleAddCallback() {
      this.showAddForm = false;
      this.getRelationshipList();
      this.getRelationshipTree();
    },
    handleRelationship(row) {
      // type 1已录入患者 2未录入患者
      console.log(row.type);
      if (row.type == 1) {
        this.$emit('handleToPatientDetail', {
          ...row,
          id: row.relevance_id
        });
      }
      if (row.type == 2) {
        this.viewFlag = true;
        this.dataInfo = row;
        this.showAddForm = true;
      }
    },
    handleRelationTreeClick(node) {
      this.handleRelationship(node.$$data);
    }
  }
});