import { defineComponent } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message-box/style/index';
import 'element-plus/es/components/message/style/index';
import BasicComp from '@/views/Basic.vue';
import { encryption } from '@/utils/validate';
import { getBasicItem } from '@/api/patient';
import { deleteProject, sortProject } from '@/api/system';
import AddForm from './add.vue';
import { Plus, ArrowUp, ArrowDown } from '@element-plus/icons-vue';
export default defineComponent({
  name: 'telPhoneTypeList',
  extends: BasicComp,
  components: {
    AddForm
  },
  data() {
    return {
      table: {
        data: [],
        column: [{
          label: '属性',
          prop: 'title'
        }, {
          label: '操作',
          prop: ''
        }]
      },
      showAddForm: false,
      dataInfo: null,
      Plus,
      ArrowUp,
      ArrowDown,
      currentRow: null
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      const {
        code,
        data
      } = await getBasicItem(encryption({
        type: '电话号码属性'
      }));
      this.table.data = data || [];
    },
    addData() {
      this.dataInfo = null;
      this.showAddForm = true;
    },
    handleAddCallback() {
      this.showAddForm = false;
      this.getDataList();
      this.$emit('handleRefresh');
    },
    handleEdit(row) {
      this.dataInfo = row;
      this.showAddForm = true;
    },
    async handleDelete(row) {
      ElMessageBox.confirm(this.delConfirmMsg, null, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {
          code,
          data
        } = await deleteProject(encryption({
          id: row.id
        }));
        if (code == 200) {
          ElMessage({
            message: this.delSuccessMsg,
            type: 'success'
          });
          this.getDataList();
          this.$emit('handleRefresh');
        }
      });
    },
    selectRows(val) {
      this.currentRow = val;
    },
    handleSortUp() {
      this.handleSort('-1');
    },
    handleSortDown() {
      this.handleSort('1');
    },
    // 1 下移 -1 上移
    async handleSort(sort) {
      if (!this.currentRow) {
        ElMessage.error('请选中数据!');
        return;
      }
      const {
        code,
        msg
      } = await sortProject(encryption({
        id: this.currentRow.id,
        sort
      }));
      if (code == 200) {
        ElMessage({
          message: '排序成功',
          type: 'success'
        });
        this.currentRow = null;
        this.getDataList();
      }
    },
    handleCancelCallback() {
      this.dataInfo = null;
      this.showAddForm = false;
    }
  }
});