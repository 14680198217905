import { defineComponent } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus/es';
import 'element-plus/es/components/message/style/index';
import 'element-plus/es/components/message-box/style/index';
import _ from 'lodash';
import BasicComp from '@/views/Basic.vue';
import { encryption } from '@/utils/validate';
import { addPatientOrigin, editPatientOrigin, delPatientOrigin, sortPatientOrigin } from '@/api/patient';
import { getUserList } from '@/api/publicApi';
export default defineComponent({
  name: 'patientOriginManagement',
  extends: BasicComp,
  props: {
    levelName: {
      type: String,
      default: ''
    },
    treeData: {
      type: Array,
      default: []
    },
    pid: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    level: {
      type: String,
      default: ''
    },
    showOperat: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    treeData: {
      handler(newValue, oldValue) {
        this.table.data = newValue || [];
      }
    }
  },
  data() {
    return {
      form: {
        id: '',
        title: ''
      },
      rules: {
        title: [{
          required: true,
          trigger: 'blur',
          message: '请输入名称'
        }],
        external_name: [{
          required: true,
          trigger: 'blur',
          message: '请输入联系人'
        }],
        external_phone: [{
          required: true,
          trigger: 'blur',
          message: '请输入联系电话'
        }],
        interior_id: [{
          required: true,
          trigger: 'blur',
          message: '请选择联系人'
        }],
        interior_phone: [{
          required: true,
          trigger: 'blur',
          message: '请输入联系电话'
        }]
      },
      showAddInfo: false,
      showEditInfo: false,
      table: {
        data: [],
        column: [{
          label: '名称',
          prop: 'title',
          width: 120
        }, {
          label: '联系人',
          prop: 'external_name',
          width: 180
        }, {
          label: '联系手机',
          prop: 'external_phone',
          width: 120
        }, {
          label: '操作',
          prop: ''
        }]
      },
      showAddDetail: false,
      contactsList: []
    };
  },
  created() {
    if (this.levelName == '三级来源') {
      this.table.data = this.treeData;
      this.getContactsList();
    }
  },
  methods: {
    addPatientOrigin() {
      this.form = {};
      if (this.levelName == '三级来源') {
        this.showAddDetail = true;
      } else {
        this.showAddInfo = true;
      }
    },
    handleAddPatientOrigin() {
      let form = JSON.parse(JSON.stringify(this.form));
      form.pid = this.pid || 0;
      this.$refs['formRef'].validate(async (valid, fields) => {
        if (valid) {
          const {
            code,
            msg
          } = await addPatientOrigin(encryption(form));
          if (code == 200) {
            ElMessage({
              message: '新建成功',
              type: 'success'
            });
            this.form = {
              id: '',
              title: ''
            }, this.showAddInfo = false;
            this.$emit('handleAddPatientOrigin', true);
          }
        }
      });
    },
    selectPatientOrigin(id) {
      this.$emit('selectPatientOrigin', id, this.level);
    },
    deletePatientOrigin() {
      ElMessageBox.confirm('此操作将永久删除该数据, 是否继续?', null, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {
          code,
          msg
        } = await delPatientOrigin(encryption({
          id: this.id
        }));
        if (code == 200) {
          ElMessage({
            message: '删除成功',
            type: 'success'
          });
          this.$emit('handleAddPatientOrigin', true, true);
        }
      });
    },
    async sortPatientOrigin(sort) {
      const {
        code,
        msg
      } = await sortPatientOrigin(encryption({
        id: this.id,
        sort
      }));
      if (code == 200) {
        this.$emit('handleAddPatientOrigin', true);
      }
    },
    editPatientOrigin() {
      const info = this.treeData.find(item => item.id == this.id);
      this.form = {
        id: info.id,
        title: info.title
      };
      this.showEditInfo = true;
    },
    async handleEditPatientOrigin() {
      let form = JSON.parse(JSON.stringify(this.form));
      const {
        code,
        msg
      } = await editPatientOrigin(encryption(form));
      if (code == 200) {
        ElMessage({
          message: '修改成功',
          type: 'success'
        });
        this.showEditInfo = false;
        this.$emit('handleAddPatientOrigin', true);
      }
    },
    // 三级来源
    async handleDelete(row) {
      ElMessageBox.confirm(this.delConfirmMsg, null, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {
          code,
          data
        } = await delPatientOrigin(encryption({
          id: row.id
        }));
        if (code == 200) {
          ElMessage({
            message: this.delSuccessMsg,
            type: 'success'
          });
          this.$emit('handleAddPatientOrigin', true, true);
        }
      });
    },
    async getContactsList() {
      const {
        code,
        data
      } = await getUserList(encryption({}));
      if (code == 200) {
        this.contactsList = data || [];
      }
    },
    handleContactChange(val) {
      this.form.interior_phone = this.contactsList.find(item => item.id == val)?.phone;
    },
    handleAddEditPatientOrigin() {
      let form = JSON.parse(JSON.stringify(this.form));
      form.pid = this.pid;
      this.$refs['formRef'].validate(async (valid, fields) => {
        if (valid) {
          if (form.id) {
            const {
              code,
              msg
            } = await editPatientOrigin(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '修改成功',
                type: 'success'
              });
              this.form = {
                id: '',
                title: ''
              }, this.showAddDetail = false;
              this.$emit('handleAddPatientOrigin', true);
            }
          } else {
            const {
              code,
              msg
            } = await addPatientOrigin(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '新建成功',
                type: 'success'
              });
              this.showAddDetail = false;
              this.$emit('handleAddPatientOrigin', true);
            }
          }
        }
      });
    },
    handleEdit(row) {
      this.form = _.cloneDeep(row);
      this.handleContactChange(row.interior_id);
      this.showAddDetail = true;
    }
  }
});