import request from '@/utils/request'

// 获取仓库列表
export async function getWarehouseList(data: any) {
  return request({
    url: '/interfaces/v1/warehouse/index',
    method: 'get',
    params: data,
  })
}
// 获取仓库列表[无权限]
export async function getWarehouseListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/warehouse/getdata',
    method: 'get',
    params: data,
  })
}
// 添加仓库
export async function addWarehouse(data: any) {
  return request({
    url: '/interfaces/v1/warehouse/add',
    method: 'post',
    data,
  })
}
// 修改仓库
export async function editWarehouse(data: any) {
  return request({
    url: '/interfaces/v1/warehouse/edit',
    method: 'post',
    data,
  })
}
// 删除仓库
export async function deleteWarehouse(data: any) {
  return request({
    url: '/interfaces/v1/warehouse/del',
    method: 'post',
    data,
  })
}


// 获取供应商列表
export async function getSupplierList(data: any) {
  return request({
    url: '/interfaces/v1/supplier/index',
    method: 'get',
    params: data,
  })
}
// 获取供应商列表[无权限]
export async function getSupplierListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/supplier/getdata',
    method: 'get',
    params: data,
  })
}
// 添加供应商
export async function addSupplier(data: any) {
  return request({
    url: '/interfaces/v1/supplier/add',
    method: 'post',
    data,
  })
}
// 修改供应商
export async function editSupplier(data: any) {
  return request({
    url: '/interfaces/v1/supplier/edit',
    method: 'post',
    data,
  })
}
// 删除供应商
export async function deleteSupplier(data: any) {
  return request({
    url: '/interfaces/v1/supplier/del',
    method: 'post',
    data,
  })
}


// 获取物品分类列表
export async function getArticleTypeList(data: any) {
  return request({
    url: '/interfaces/v1/articleType/index',
    method: 'get',
    params: data,
  })
}
// 获取物品分类列表[无权限]
export async function getArticleTypeListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/articleType/getdata',
    method: 'get',
    params: data,
  })
}
// 添加物品分类
export async function addArticleType(data: any) {
  return request({
    url: '/interfaces/v1/articleType/add',
    method: 'post',
    data,
  })
}
// 修改物品分类
export async function editArticleType(data: any) {
  return request({
    url: '/interfaces/v1/articleType/edit',
    method: 'post',
    data,
  })
}
// 删除物品分类
export async function deleteArticleType(data: any) {
  return request({
    url: '/interfaces/v1/articleType/del',
    method: 'post',
    data,
  })
}
// 排序物品分类
export async function sortArticleType(data: any) {
  return request({
    url: '/interfaces/v1/articleType/sort',
    method: 'post',
    data,
  })
}


// 获取物品列表
export async function getArticleList(data: any) {
  return request({
    url: '/interfaces/v1/article/index',
    method: 'get',
    params: data,
  })
}
// 获取物品列表[无权限]
export async function getArticleListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/article/getdata',
    method: 'get',
    params: data,
  })
}
// 获取物品列表-按规格[无权限]
export async function getArticleAttrsListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/article/getDataByAttr',
    method: 'get',
    params: data,
  })
}
// 获取物品详情[无权限]
export async function getArticleDetailNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/article/getInfo',
    method: 'get',
    params: data,
  })
}
// 添加物品
export async function addArticle(data: any) {
  return request({
    url: '/interfaces/v1/article/add',
    method: 'post',
    data,
  })
}
// 修改物品分类
export async function editArticle(data: any) {
  return request({
    url: '/interfaces/v1/article/edit',
    method: 'post',
    data,
  })
}
// 删除物品分类
export async function deleteArticle(data: any) {
  return request({
    url: '/interfaces/v1/article/del',
    method: 'post',
    data,
  })
}
// 导出物品
export async function exportArticles(data: any) {
  return request({
    url: '/interfaces/v1/article/export',
    method: 'get',
    params: data,
  })
}
// 获取预警
export async function getWarningInfo(data: any) {
  return request({
    url: '/interfaces/v1/article/forewarning',
    method: 'get',
    params: data,
  })
}
// 设置预警
export async function setWarningInfo(data: any) {
  return request({
    url: '/interfaces/v1/article/setForewarning',
    method: 'post',
    data,
  })
}

// 物品设置相关列表
export async function getArticlesInfoList(data: any) {
  return request({
    url: '/interfaces/v1/stockInformation/getdata',
    method: 'get',
    params: data,
  })
}
// 物品设置相关添加
export async function addArticlesInfo(data: any) {
  return request({
    url: '/interfaces/v1/stockInformation/add',
    method: 'post',
    data,
  })
}
// 物品设置相关编辑
export async function editArticlesInfo(data: any) {
  return request({
    url: '/interfaces/v1/stockInformation/edit',
    method: 'post',
    data,
  })
}
// 物品设置相关排序
export async function sortArticlesInfo(data: any) {
  return request({
    url: '/interfaces/v1/stockInformation/sort',
    method: 'post',
    data,
  })
}
// 物品设置相关删除
export async function deleteArticlesInfo(data: any) {
  return request({
    url: '/interfaces/v1/stockInformation/del',
    method: 'post',
    data,
  })
}


// 新建入库
export async function addInStorageInfo(data: any) {
  return request({
    url: '/interfaces/v1/stock/inStorage',
    method: 'post',
    data,
  })
}
// 修改入库
export async function editInStorageInfo(data: any) {
  return request({
    url: '/interfaces/v1/stock/inStorageEdit',
    method: 'post',
    data,
  })
}
// 获取入库列表
export async function getInStorageList(data: any) {
  return request({
    url: '/interfaces/v1/stock/getInStorage',
    method: 'get',
    params: data,
  })
}
// 修改库状态
export async function setStorageStatus(data: any) {
  return request({
    url: '/interfaces/v1/stock/setStorageStatus',
    method: 'post',
    data,
  })
}
// 导出入库
export async function exportInStorage(data: any) {
  return request({
    url: '/interfaces/v1/stock/exportStorage',
    method: 'get',
    params: data,
  })
}

// 新建出库
export async function addOutStorageInfo(data: any) {
  return request({
    url: '/interfaces/v1/stock/outStorage',
    method: 'post',
    data,
  })
}
// 修改出库
export async function editOutStorageInfo(data: any) {
  return request({
    url: '/interfaces/v1/stock/outStorageEdit',
    method: 'post',
    data,
  })
}
// 获取出库列表
export async function getOutStorageList(data: any) {
  return request({
    url: '/interfaces/v1/stock/getOutStorage',
    method: 'get',
    params: data,
  })
}
// 导出出库
export async function exportOutStorage(data: any) {
  return request({
    url: '/interfaces/v1/stock/exportStorage',
    method: 'get',
    params: data,
  })
}



// 新建调拨
export async function addAllotStorageInfo(data: any) {
  return request({
    url: '/interfaces/v1/stock/allotAdd',
    method: 'post',
    data,
  })
}
// 修改调拨
export async function editAllotStorageInfo(data: any) {
  return request({
    url: '/interfaces/v1/stock/allotEdit',
    method: 'post',
    data,
  })
}
// 获取调拨列表
export async function getAllotStorageList(data: any) {
  return request({
    url: '/interfaces/v1/stock/getAllot',
    method: 'get',
    params: data,
  })
}
// 修改调拨状态
export async function setAllotStorageStatus(data: any) {
  return request({
    url: '/interfaces/v1/stock/allotStatus',
    method: 'post',
    data,
  })
}
// 导出调拨
export async function exportAllotStorage(data: any) {
  return request({
    url: '/interfaces/v1/stock/exportAllot',
    method: 'get',
    params: data,
  })
}


// 获取盘点列表
export async function getCheckStorageList(data: any) {
  return request({
    url: '/interfaces/v1/stock/getChaku',
    method: 'get',
    params: data,
  })
}
// 新建盘点
export async function addCheckStorageInfo(data: any) {
  return request({
    url: '/interfaces/v1/stock/chakuAdd',
    method: 'post',
    data,
  })
}
// 修改盘点
export async function editCheckStorageInfo(data: any) {
  return request({
    url: '/interfaces/v1/stock/chakuEdit',
    method: 'post',
    data,
  })
}
// 修改盘点状态
export async function setCheckStorageStatus(data: any) {
  return request({
    url: '/interfaces/v1/stock/chakuStatus',
    method: 'post',
    data,
  })
}
// 导出盘点
export async function exportCheckStorage(data: any) {
  return request({
    url: '/interfaces/v1/stock/exportChaku',
    method: 'get',
    params: data,
  })
}



// 获取库存-按物品
export async function getStorageByArticle(data: any) {
  return request({
    url: '/interfaces/v1/stock/getdataByArticles',
    method: 'get',
    params: data,
  })
}
// 获取库存-按规格
export async function getStorageByArticleAttrs(data: any) {
  return request({
    url: '/interfaces/v1/stock/getdata',
    method: 'get',
    params: data,
  })
}
// 获取库存数量
export async function getNumStorageInfo(data: any) {
  return request({
    url: '/interfaces/v1/stock/getNumber',
    method: 'get',
    params: data,
  })
}
// 获取待处理数量
export async function getStorageNumStatistics(data: any) {
  return request({
    url: '/interfaces/v1/stock/getStorageNumber',
    method: 'get',
    params: data,
  })
}
// 获取库存列表
export async function getViewStorageList(data: any) {
  return request({
    url: '/interfaces/v1/stock/index',
    method: 'get',
    params: data,
  })
}
// 导出库存
export async function exportStorageList(data: any) {
  return request({
    url: '/interfaces/v1/stock/export',
    method: 'get',
    params: data,
  })
}


// 获取领用列表
export async function getConsumingList(data: any) {
  return request({
    url: '/interfaces/v1/receive/index',
    method: 'get',
    params: data,
  })
}
// 新建领用
export async function addConsumingInfo(data: any) {
  return request({
    url: '/interfaces/v1/receive/add',
    method: 'post',
    data,
  })
}
// 修改领用
export async function editConsumingInfo(data: any) {
  return request({
    url: '/interfaces/v1/receive/edit',
    method: 'post',
    data,
  })
}
// 修改领用状态
export async function setConsumingStatus(data: any) {
  return request({
    url: '/interfaces/v1/receive/status',
    method: 'post',
    data,
  })
}
// 导出领用
export async function exportConsumingInfo(data: any) {
  return request({
    url: '/interfaces/v1/receive/export',
    method: 'get',
    params: data,
  })
}

// 获取采购助手列表
export async function getPurAssistantList(data: any) {
  return request({
    url: '/interfaces/v1/purchase/getData',
    method: 'get',
    params: data,
  })
}
// 导出采购助手
export async function exportPurAssistantList(data: any) {
  return request({
    url: '/interfaces/v1/purchase/export',
    method: 'get',
    params: data,
  })
}

// 获取采购列表
export async function getPurOrderList(data: any) {
  return request({
    url: '/interfaces/v1/purchase/index',
    method: 'get',
    params: data,
  })
}
/// 新建采购
export async function addPurOrderInfo(data: any) {
  return request({
    url: '/interfaces/v1/purchase/add',
    method: 'post',
    data,
  })
}
// 修改采购
export async function editPurOrderInfo(data: any) {
  return request({
    url: '/interfaces/v1/purchase/edit',
    method: 'post',
    data,
  })
}
// 修改采购状态
export async function setPurOrderStatus(data: any) {
  return request({
    url: '/interfaces/v1/purchase/status',
    method: 'post',
    data,
  })
}
// 导出采购-[单条]
export async function exportOnePurOrder(data: any) {
  return request({
    url: '/interfaces/v1/purchase/exportinfo',
    method: 'get',
    params: data,
  })
}
// 导出采购-[全]
export async function exportAllPurOrder(data: any) {
  return request({
    url: '/interfaces/v1/purchase/exportlist',
    method: 'get',
    params: data,
  })
}

// 新建退货单
export async function addSalesReturnInfo(data: any) {
  return request({
    url: '/interfaces/v1/purchase/refund',
    method: 'post',
    data,
  })
}
// 退货单列表
export async function getSalesReturnList(data: any) {
  return request({
    url: '/interfaces/v1/purchase/getRefund',
    method: 'get',
    params: data,
  })
}
// 修改退货状态
export async function setSalesReturnStatus(data: any) {
  return request({
    url: '/interfaces/v1/purchase/refundVoid',
    method: 'post',
    data,
  })
}
// 完善退货信息
export async function addCompleteSalesReturnInfo(data: any) {
  return request({
    url: '/interfaces/v1/purchase/refundEdit',
    method: 'post',
    data,
  })
}
// 导出退货
export async function exportSalesReturn(data: any) {
  return request({
    url: '/interfaces/v1/purchase/exportback',
    method: 'get',
    params: data,
  })
}


// 获取库存流水
export async function getStockRecordList(data: any) {
  return request({
    url: '/interfaces/v1/stock/getWaterSingle',
    method: 'get',
    params: data,
  })
}
// 导出库存流水
export async function exportStockRecord(data: any) {
  return request({
    url: '/interfaces/v1/stock/exportWaterSingle',
    method: 'get',
    params: data,
  })
}

// 获取待采物品列表
export async function getPurWaittingList(data: any) {
  return request({
    url: '/interfaces/v1/PurchaseWait/index',
    method: 'get',
    params: data,
  })
}
/// 新建待采
export async function addPurWaittingInfo(data: any) {
  return request({
    url: '/interfaces/v1/PurchaseWait/add',
    method: 'post',
    data,
  })
}
// 修改待采状态
export async function setPurWaittingStatus(data: any) {
  return request({
    url: '/interfaces/v1/PurchaseWait/del',
    method: 'post',
    data,
  })
}


