import request from '@/utils/request'
// 省市区去获取
export function getarea(data: any) {
  return request({
    url: '/interfaces/getarea?parent='+data,
    method: 'get',
  })
}

// 获取岗位列表
export function getPostList(data: any) {
  return request({
    url: '/interfaces/v1/getposition',
    method: 'get',
    params: data,
  })
}

// 获取岗位人员列表
export function getUserList(data: any) {
  return request({
    url: '/interfaces/v1/staff/getData',
    method: 'get',
    params: data,
  })
}

// 获取牙色号
export function getToothColorList(data: any) {
  return request({
    url: '/interfaces/v1/getToothColor',
    method: 'get',
    params: data,
  })
}

// 获取快递公司
export function getExpressList(data: any) {
  return request({
    url: '/interfaces/v1/getExpress',
    method: 'get',
    params: data,
  })
}

// 获取店铺配置
export function getShopConfigInfo(data: any) {
  return request({
    url: '/interfaces/v1/getConfig',
    method: 'get',
    params: data,
  })
}


// 入库类型列表
export function getInStorageTypeList(data: any) {
  return request({
    url: '/interfaces/v1/receiptType',
    method: 'get',
    params: data,
  })
}
// 出库类型列表
export function getOutStorageTypeList(data: any) {
  return request({
    url: '/interfaces/v1/issueType',
    method: 'get',
    params: data,
  })
}


// 获取诊室列表
export function getConsultingList(data: any) {
  return request({
    url: '/interfaces/v1/consulting/getdata',
    method: 'get',
    params: data,
  })
}


// 获取审批标记
export function getApproveSettingFlag(data: any) {
  return request({
    url: '/interfaces/v1/IsAudit',
    method: 'get',
    params: data,
  })
}


export function getSystemColorList(data: any) {
  return request({
    url: '/interfaces/v1/getColorSystem',
    method: 'get',
    params: data,
  })
}
export function getStateColorList(data: any) {
  return request({
    url: '/interfaces/v1/getStateColor',
    method: 'get',
    params: data,
  })
}


// 获取自定义列配置
export function getCustomColumnList(data: any) {
  return request({
    url: '/interfaces/v1/getCustomColumn',
    method: 'get',
    params: data,
  })
}
export function setCustomColumn(data: any) {
  return request({
    url: '/interfaces/v1/setCustomColumn',
    method: 'POST',
    data: data,
  })
}


// 获取配置项
export function getConfigDataList(data: any) {
  return request({
    url: '/interfaces/v1/config/index',
    method: 'get',
    params: data,
  })
}
// 添加配置项
export function addConfigData(data: any) {
  return request({
    url: '/interfaces/v1/config/add',
    method: 'POST',
    data: data,
  })
}
// 编辑配置项
export function editConfigData(data: any) {
  return request({
    url: '/interfaces/v1/config/edit',
    method: 'POST',
    data: data,
  })
}
// 排序配置项
export function sortConfigData(data: any) {
  return request({
    url: '/interfaces/v1/config/sort',
    method: 'POST',
    data: data,
  })
}
// 删除配置项
export function delConfigData(data: any) {
  return request({
    url: '/interfaces/v1/config/del',
    method: 'POST',
    data: data,
  })
}



