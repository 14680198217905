import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-46ad593c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  key: 0,
  style: {
    "margin-top": "15px",
    "width": "100%"
  }
};
const _hoisted_3 = {
  key: 0,
  class: "btns"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  key: 2
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 3
};
const _hoisted_8 = {
  key: 4
};
const _hoisted_9 = {
  key: 1,
  style: {
    "width": "100%",
    "height": "400px"
  }
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = {
  key: 0,
  class: "custom-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Plus = _resolveComponent("Plus");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_Menu = _resolveComponent("Menu");
  const _component_Expand = _resolveComponent("Expand");
  const _component_Edit = _resolveComponent("Edit");
  const _component_Delete = _resolveComponent("Delete");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_image = _resolveComponent("el-image");
  const _component_Avatar = _resolveComponent("Avatar");
  const _component_vue3_tree_org = _resolveComponent("vue3-tree-org");
  const _component_AddForm = _resolveComponent("AddForm");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_icon, {
    class: "btn",
    onClick: _ctx.addData
  }, {
    default: _withCtx(() => [_createVNode(_component_Plus)]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_icon, {
    class: "btn",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.handleChangeType('icon'))
  }, {
    default: _withCtx(() => [_createVNode(_component_Menu)]),
    _: 1
  }), _createVNode(_component_el_icon, {
    class: "btn",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.handleChangeType('list'))
  }, {
    default: _withCtx(() => [_createVNode(_component_Expand)]),
    _: 1
  })]), _ctx.showType == 'list' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_table, {
    ref: "tableSortRef",
    border: true,
    data: _ctx.table.data,
    "row-class-name": _ctx.tableRowClassName
  }, {
    empty: _withCtx(() => [_createVNode(_component_el_empty, {
      class: "vab-data-empty",
      description: "暂无数据",
      "image-size": 50
    })]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.column, (item, index) => {
      return _openBlock(), _createBlock(_component_el_table_column, {
        key: index,
        align: item.align || 'center',
        label: item.label,
        "min-width": item.minWidth,
        prop: item.prop,
        type: item.type,
        width: item.width,
        fixed: item.fixed || false
      }, {
        default: _withCtx(({
          row
        }) => [item.label == '操作' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_icon, {
          onClick: $event => _ctx.handleEdit(row),
          class: "btn"
        }, {
          default: _withCtx(() => [_createVNode(_component_Edit)]),
          _: 2
        }, 1032, ["onClick"]), _createVNode(_component_el_icon, {
          onClick: $event => _ctx.handleDelete(row),
          class: "btn"
        }, {
          default: _withCtx(() => [_createVNode(_component_Delete)]),
          _: 2
        }, 1032, ["onClick"])])) : item.label == '性别' ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.getSexName(row[item.prop])), 1)) : item.label == '亲友' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", {
          onClick: $event => _ctx.handleRelationship(row),
          class: "relationBtn"
        }, _toDisplayString(row[item.prop]), 9, _hoisted_6)])) : item.label == '病历号' ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(row[item.prop] || '*(无病历号)'), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(row[item.prop]), 1))]),
        _: 2
      }, 1032, ["align", "label", "min-width", "prop", "type", "width", "fixed"]);
    }), 128))]),
    _: 1
  }, 8, ["data", "row-class-name"])])) : _createCommentVNode("", true), _ctx.showType == 'icon' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_vue3_tree_org, {
    data: _ctx.relationshipData,
    horizontal: _ctx.horizontal,
    collapsable: _ctx.collapsable,
    "label-style": _ctx.style,
    "node-draggable": false,
    scalable: false,
    "only-one-node": _ctx.onlyOneNode,
    "default-expand-level": 3
  }, {
    default: _withCtx(({
      node
    }) => [_createElementVNode("div", {
      onClick: $event => _ctx.handleRelationTreeClick(node),
      class: "tree-org-node__text node-label"
    }, [node.pid != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_11, [node.$$data.avatar ? (_openBlock(), _createBlock(_component_el_image, {
      key: 0,
      src: node.$$data.avatar,
      class: "avatar"
    }, null, 8, ["src"])) : (_openBlock(), _createBlock(_component_el_icon, {
      key: 1,
      size: "40px"
    }, {
      default: _withCtx(() => [_createVNode(_component_Avatar)]),
      _: 1
    }))])) : _createCommentVNode("", true), _createElementVNode("div", null, _toDisplayString(node.label), 1)], 8, _hoisted_10)]),
    _: 1
  }, 8, ["data", "horizontal", "collapsable", "label-style", "only-one-node"])])) : _createCommentVNode("", true), _createVNode(_component_el_dialog, {
    "append-to-body": true,
    modelValue: _ctx.showAddForm,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showAddForm = $event),
    title: "亲友管理",
    width: "500px",
    "close-on-click-modal": false,
    "destroy-on-close": true
  }, {
    default: _withCtx(() => [_createVNode(_component_AddForm, {
      patientId: _ctx.id,
      dataInfo: _ctx.dataInfo,
      viewFlag: _ctx.viewFlag,
      onHandleAddCallback: _ctx.handleAddCallback,
      onHandleCancelCallback: _ctx.handleCancelCallback
    }, null, 8, ["patientId", "dataInfo", "viewFlag", "onHandleAddCallback", "onHandleCancelCallback"])]),
    _: 1
  }, 8, ["modelValue"])], 64);
}