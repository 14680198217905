import request from '@/utils/request'
export function getList() {
  return request({
    url: '/interfaces/v1/login/Menu',
    method: 'get',
  })
}

// 按钮权限列表
export function getBtnList() {
  return request({
    url: '/interfaces/v1/login/buttonMenu',
    method: 'get',
  })
}

