import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "formRef",
    model: _ctx.form,
    rules: _ctx.rules,
    "label-width": "80px",
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 20
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "名称",
          prop: "title"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.form.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.form.title = $event),
            placeholder: "请输入名称"
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        class: "text-btn",
        type: "primary",
        onClick: _ctx.handleAddData
      }, {
        default: _withCtx(() => [_createTextVNode(" 保存 ")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        class: "text-btn",
        onClick: _ctx.handleCancel
      }, {
        default: _withCtx(() => [_createTextVNode("取消")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]);
}