import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/index';
import BasicComp from '@/views/Basic.vue';
import { getBasicItem, addCaseRules, editCaseRules } from '@/api/patient';
import { encryption } from '@/utils/validate';
import { cloneDeep } from 'lodash';
export default defineComponent({
  extends: BasicComp,
  props: {
    dataInfo: {
      type: Object,
      default: null
    },
    rulesArray: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      form: {
        is_prefix: 0,
        date_type: 0
      },
      rules: {
        type_id: [{
          required: true,
          trigger: 'blur',
          message: '请选择患者类型'
        }],
        prefix_value: [{
          validator: this.validatePrefixValue,
          trigger: 'blur',
          message: '请输入前缀'
        }]
      },
      patientType: []
    };
  },
  created() {
    if (this.dataInfo) {
      this.form = {
        ...this.dataInfo
      };
    }
    this.getBasicItem('患者类型');
  },
  methods: {
    async getBasicItem(type) {
      const {
        code,
        data
      } = await getBasicItem(encryption({
        type
      }));
      if (this.dataInfo) {
        this.patientType = data;
      } else {
        this.patientType = data?.map(item => {
          if (!this.rulesArray.includes(item.title)) {
            return item;
          }
        });
      }
    },
    validatePrefixValue(rule, value, callback) {
      if (this.form.is_prefix == 1 && !this.form.prefix_value) {
        callback(new Error(translate('请输入前缀 ！')));
      } else {
        callback();
      }
    },
    handleAddData() {
      let form = cloneDeep(this.form);
      this.$refs['formRef'].validate(async (valid, fields) => {
        if (valid) {
          if (form.id) {
            const {
              code,
              msg
            } = await editCaseRules(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '修改成功',
                type: 'success'
              });
              this.$emit('handleAddCallback', 'save', status);
            }
          } else {
            const {
              code,
              msg
            } = await addCaseRules(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '新建成功',
                type: 'success'
              });
              this.$emit('handleAddCallback', 'save', status);
            }
          }
        }
      });
    },
    handleCancel() {
      this.$emit('handleAddCallback', 'cancel');
    }
  }
});