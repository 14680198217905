import request from '@/utils/request'

// 获取基础项目列表
export async function getBasicItem(data: any) {
  return request({
    url: '/interfaces/v1/basic/getdata',
    method: 'get',
    params: data,
  })
}

// 获取病历号
export async function getCaseNO(data: any) {
  return request({
    url: '/interfaces/v1/numberRule/getnumber',
    method: 'get',
    params: data,
  })
}

// 获取病历号规则列表
export async function getCaseRulesList(data: any) {
  return request({
    url: '/interfaces/v1/numberRule/index',
    method: 'get',
    params: data,
  })
}
// 新增病历号规则
export async function addCaseRules(data: any) {
  return request({
    url: '/interfaces/v1/numberRule/add',
    method: 'post',
    data,
  })
}
// 修改病历号规则
export async function editCaseRules(data: any) {
  return request({
    url: '/interfaces/v1/numberRule/edit',
    method: 'post',
    data,
  })
}
// 删除病历号规则
export async function delCaseRules(data: any) {
  return request({
    url: '/interfaces/v1/numberRule/del',
    method: 'post',
    data,
  })
}


// 获取国籍列表
export async function getNationalityList(data: any) {
  return request({
    url: '/interfaces/v1/nationality/index',
    method: 'get',
    params: data,
  })
}
// 新增国籍
export async function addNationality(data: any) {
  return request({
    url: '/interfaces/v1/nationality/add',
    method: 'post',
    data,
  })
}
// 修改国籍
export async function editNationality(data: any) {
  return request({
    url: '/interfaces/v1/nationality/edit',
    method: 'post',
    data,
  })
}
// 删除国籍
export async function delNationality(data: any) {
  return request({
    url: '/interfaces/v1/nationality/del',
    method: 'post',
    data,
  })
}
// 排序国籍
export async function sortNationality(data: any) {
  return request({
    url: '/interfaces/v1/nationality/sort',
    method: 'post',
    data,
  })
}


// 获取患者来源[树状结构]
export async function getPatientOriginTree(data: any) {
  return request({
    url: '/interfaces/v1/source/getdata',
    method: 'get',
    params: data,
  })
}
// 获取患者来源列表
export async function getPatientOriginList(data: any) {
  return request({
    url: '/interfaces/v1/source/index',
    method: 'get',
    params: data,
  })
}
// 新增来源
export async function addPatientOrigin(data: any) {
  return request({
    url: '/interfaces/v1/source/add',
    method: 'post',
    data,
  })
}
// 修改来源
export async function editPatientOrigin(data: any) {
  return request({
    url: '/interfaces/v1/source/edit',
    method: 'post',
    data,
  })
}
// 删除来源
export async function delPatientOrigin(data: any) {
  return request({
    url: '/interfaces/v1/source/del',
    method: 'post',
    data,
  })
}


// 获取系统标签列表
export async function getPatientTagList(data: any) {
  return request({
    url: '/interfaces/v1/patientTag/getdata',
    method: 'get',
    params: data,
  })
}


// 获取亲友关系列表
export async function getRelationshipList(data: any) {
  return request({
    url: '/interfaces/v1/friends/index',
    method: 'get',
    params: data,
  })
}
// 获取亲友关系图
export async function getRelationshipTree(data: any) {
  return request({
    url: '/interfaces/v1/friends/group',
    method: 'get',
    params: data,
  })
}
// 新增亲友关系
export async function addRelationship(data: any) {
  return request({
    url: '/interfaces/v1/friends/add',
    method: 'post',
    data,
  })
}
// 修改亲友关系
export async function editRelationship(data: any) {
  return request({
    url: '/interfaces/v1/friends/edit',
    method: 'post',
    data,
  })
}
// 删除亲友关系
export async function delRelationship(data: any) {
  return request({
    url: '/interfaces/v1/friends/del',
    method: 'post',
    data,
  })
}


// 获取必填项列表
export async function getRequiredFieldsList(data: any) {
  return request({
    url: '/interfaces/v1/requiredFields/getdata',
    method: 'get',
    params: data,
  })
}


// 获取患者列表
export async function getPatientList(data: any) {
  return request({
    url: '/interfaces/v1/patient/index',
    method: 'get',
    params: data,
  })
}
// 获取患者列表[无权限]
export async function getPatientListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/patient/getData',
    method: 'get',
    params: data,
  })
}
// 新增患者
export async function addPatient(data: any) {
  return request({
    url: '/interfaces/v1/patient/add',
    method: 'post',
    data,
  })
}
export async function addPatientQuick(data: any) {
  return request({
    url: '/interfaces/v1/patient/quickAdd',
    method: 'post',
    data,
  })
}
// 修改患者
export async function editPatient(data: any) {
  return request({
    url: '/interfaces/v1/patient/edit',
    method: 'post',
    data,
  })
}
// 删除患者
export async function delPatient(data: any) {
  return request({
    url: '/interfaces/v1/patient/del',
    method: 'post',
    data,
  })
}
// 患者详情
export async function getPatientDetail(data: any) {
  return request({
    url: '/interfaces/v1/patient/getInfo',
    method: 'get',
    params: data,
  })
}
// 设置分组
export async function setPatientGroup(data: any) {
  return request({
    url: '/interfaces/v1/patient/setGroup',
    method: 'post',
    data,
  })
}



// 获取分组列表
export async function getPatientGroupList(data: any) {
  return request({
    url: '/interfaces/v1/group/index',
    method: 'get',
    params: data,
  })
}
// 新增分组
export async function addPatientGroup(data: any) {
  return request({
    url: '/interfaces/v1/group/add',
    method: 'post',
    data,
  })
}
// 修改分组
export async function editPatientGroup(data: any) {
  return request({
    url: '/interfaces/v1/group/edit',
    method: 'post',
    data,
  })
}
// 删除患者分组
export async function delPatientGroup(data: any) {
  return request({
    url: '/interfaces/v1/group/del',
    method: 'post',
    data,
  })
}
// 移动可加入分组
export async function getMovePatientGroup(data: any) {
  return request({
    url: '/interfaces/v1/group/getdata',
    method: 'get',
    params: data,
  })  
}


// 获取就诊记录
export async function getPatientReservationRecord(data: any) {
  return request({
    url: '/interfaces/v1/reservationLog/index',
    method: 'get',
    params: data,
  })
}


// 获取随访列表
export async function getFollowupList(data: any) {
  return request({
    url: '/interfaces/v1/patientFollowup/index',
    method: 'get',
    params: data,
  })
}
// 获取随访详情
export async function getFollowupDetail(data: any) {
  return request({
    url: '/interfaces/v1/patientFollowup/getInfo',
    method: 'get',
    params: data,
  })
}
// 新增随访
export async function addFollowup(data: any) {
  return request({
    url: '/interfaces/v1/patientFollowup/add',
    method: 'post',
    data,
  })
}
// 修改随访
export async function editFollowup(data: any) {
  return request({
    url: '/interfaces/v1/patientFollowup/edit',
    method: 'post',
    data,
  })
}
// 执行随访
export async function runFollowup(data: any) {
  return request({
    url: '/interfaces/v1/patientFollowup/execute',
    method: 'post',
    data,
  })
}
// 取消随访
export async function cancelFollowup(data: any) {
  return request({
    url: '/interfaces/v1/patientFollowup/cancel',
    method: 'post',
    data,
  })
}
// 再次随访
export async function againFollowup(data: any) {
  return request({
    url: '/interfaces/v1/patientFollowup/againFollowup',
    method: 'post',
    data,
  })
}
// 删除随访
export async function delFollowup(data: any) {
  return request({
    url: '/interfaces/v1/patientFollowup/del',
    method: 'post',
    data,
  })
}


// 获取回访列表
export async function getVisitList(data: any) {
  return request({
    url: '/interfaces/v1/returnVisit/index',
    method: 'get',
    params: data,
  })
}
// 获取回访详情
export async function getVisitDetail(data: any) {
  return request({
    url: '/interfaces/v1/returnVisit/getInfo',
    method: 'get',
    params: data,
  })
}
// 新增回访
export async function addVisitInfo(data: any) {
  return request({
    url: '/interfaces/v1/returnVisit/add',
    method: 'post',
    data,
  })
}
// 修改回访
export async function editVisitInfo(data: any) {
  return request({
    url: '/interfaces/v1/returnVisit/edit',
    method: 'post',
    data,
  })
}
// 执行回访
export async function runVisitInfo(data: any) {
  return request({
    url: '/interfaces/v1/returnVisit/execute',
    method: 'post',
    data,
  })
}
// 取消回访
export async function cancelVisitInfo(data: any) {
  return request({
    url: '/interfaces/v1/returnVisit/cancel',
    method: 'post',
    data,
  })
}
// 再次回访
export async function againVisitInfo(data: any) {
  return request({
    url: '/interfaces/v1/returnVisit/againFollowup',
    method: 'post',
    data,
  })
}
// 删除回访
export async function delVisitInfo(data: any) {
  return request({
    url: '/interfaces/v1/returnVisit/del',
    method: 'post',
    data,
  })
}


// 获取咨询列表
export async function getConsultRecordList(data: any) {
  return request({
    url: '/interfaces/v1/consultingRecords/index',
    method: 'get',
    params: data,
  })
}
// 获取咨询详情
export async function getConsultRecordDetail(data: any) {
  return request({
    url: '/interfaces/v1/consultingRecords/getInfo',
    method: 'get',
    params: data,
  })
}
// 新增咨询
export async function addConsultRecord(data: any) {
  return request({
    url: '/interfaces/v1/consultingRecords/add',
    method: 'post',
    data,
  })
}
// 修改咨询
export async function editConsultRecord(data: any) {
  return request({
    url: '/interfaces/v1/consultingRecords/edit',
    method: 'post',
    data,
  })
}
// 删除咨询
export async function delConsultRecord(data: any) {
  return request({
    url: '/interfaces/v1/consultingRecords/del',
    method: 'post',
    data,
  })
}


// 获取影像类别
export async function getImagingTypeList(data: any) {
  return request({
    url: '/interfaces/v1/imaging/getType',
    method: 'get',
    params: data,
  })
}
// 影像列表
export async function getImagingDataList(data: any) {
  return request({
    url: '/interfaces/v1/imaging/index',
    method: 'get',
    params: data,
  })
}
export async function getImagingDataListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/imaging/getList',
    method: 'get',
    params: data,
  })
}
// 新增影像
export async function addImagingData(data: any) {
  return request({
    url: '/interfaces/v1/imaging/add',
    method: 'post',
    data,
  })
}
export async function addImagingDataNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/imaging/insertion',
    method: 'post',
    data,
  })
}
// 删除影像
export async function delImagingData(data: any) {
  return request({
    url: '/interfaces/v1/imaging/del',
    method: 'post',
    data,
  })
}


// 获取病例字段显示&必填
export async function getCaseFieldsList(data: any) {
  return request({
    url: '/interfaces/v1/cases/getFields',
    method: 'get',
    params: data,
  })
}

// 病历标签
export async function getCaseTagList(data: any) {
  return request({
    url: '/interfaces/v1/caseLabel/index',
    method: 'get',
    params: data,
  })
}
export async function addCaseTag(data: any) {
  return request({
    url: 'interfaces/v1/caseLabel/add',
    method: 'post',
    data,
  })
}
export async function editCaseTag(data: any) {
  return request({
    url: 'interfaces/v1/caseLabel/edit',
    method: 'post',
    data,
  })
}
export async function delCaseTag(data: any) {
  return request({
    url: '/interfaces/v1/caseLabel/del',
    method: 'post',
    data,
  })
}
export async function sortCaseTag(data: any) {
  return request({
    url: '/interfaces/v1/caseLabel/sort',
    method: 'post',
    data,
  })
}


// 电子病历
export async function getCaseList(data: any) {
  return request({
    url: '/interfaces/v1/cases/index',
    method: 'get',
    params: data,
  })
}
export async function addCase(data: any) {
  return request({
    url: '/interfaces/v1/cases/add',
    method: 'post',
    data,
  })
}
export async function editCase(data: any) {
  return request({
    url: '/interfaces/v1/cases/edit',
    method: 'post',
    data,
  })
}
export async function delCase(data: any) {
  return request({
    url: '/interfaces/v1/cases/del',
    method: 'post',
    data,
  })
}
export async function updateTreatmentStatus(data: any) {
  return request({
    url: '/interfaces/v1/cases/setItemStatus',
    method: 'post',
    data,
  })
}
export async function updateTreatmentData(data: any) {
  return request({
    url: '/interfaces/v1/cases/setTreatmentPlan',
    method: 'post',
    data,
  })
}


// 正畸数据字典
export async function getOrthodonticsDicts(data: any) {
  return request({
    url: '/interfaces/v1/orthodontics/select',
    method: 'get',
    params: data,
  })
}
// 修改正畸信息
export async function editOrthodontics(data: any) {
  return request({
    url: '/interfaces/v1/orthodontics/edit',
    method: 'post',
    data,
  })
}
// 获取正畸信息
export async function getOrthodontics(data: any) {
  return request({
    url: '/interfaces/v1/orthodontics/index',
    method: 'get',
    params: data,
  })
}
// 正畸字段显示
export async function getOrthodonticsFields(data: any) {
  return request({
    url: '/interfaces/v1/orthodontics/getfield',
    method: 'get',
    params: data,
  })
}
export async function setOrthodonticsFields(data: any) {
  return request({
    url: '/interfaces/v1/orthodontics/setfield',
    method: 'post',
    data,
  })
}


// 收费
// 获取账单列表
export async function getChargeRecordList(data: any) {
  return request({
    url: '/interfaces/v1/charge/index',
    method: 'get',
    params: data,
  })
}
// 获取收费项目列表
export async function getChargeItemList(data: any) {
  return request({
    url: '/interfaces/v1/charge/getchargeitem',
    method: 'get',
    params: data,
  })
}
// 添加收费
export async function addCharge(data: any) {
  return request({
    url: '/interfaces/v1/charge/add',
    method: 'post',
    data,
  })
}
export async function editCharge(data: any) {
  return request({
    url: '/interfaces/v1/charge/edit',
    method: 'post',
    data,
  })
}

// 获取收费明细
export async function getChargeFeeDetail(data: any) {
  return request({
    url: '/interfaces/v1/charge/getItem',
    method: 'get',
    params: data,
  })
}
export async function getChargeFeePayDetail(data: any) {
  return request({
    url: '/interfaces/v1/charge/getPaylog',
    method: 'get',
    params: data,
  })
}
// 删除收费账单
export async function deleteChargeFee(data: any) {
  return request({
    url: '/interfaces/v1/charge/del',
    method: 'post',
    data,
  })
}
// 作废账单
export async function changeChargeStatus(data: any) {
  return request({
    url: '/interfaces/v1/charge/status',
    method: 'post',
    data,
  })
}
// 退费处理
export async function refundCharge(data: any) {
  return request({
    url: '/interfaces/v1/charge/refund',
    method: 'post',
    data,
  })
}
// 支付记录
export async function getChargePaylogList(data: any) {
  return request({
    url: '/interfaces/v1/charge/getChargePaylog',
    method: 'get',
    params: data,
  })
}
// 收费
export async function addChargeRecord(data: any) {
  return request({
    url: '/interfaces/v1/charge/arrearsCollected',
    method: 'post',
    data,
  })
}



// 处置记录
export async function getDisposeRecordList(data: any) {
  return request({
    url: '/interfaces/v1/disposal/index',
    method: 'get',
    params: data,
  })
}
// 添加处置
export async function addDisposeRecord(data: any) {
  return request({
    url: '/interfaces/v1/disposal/add',
    method: 'post',
    data,
  })
}
// 编辑处置
export async function editDisposeRecord(data: any) {
  return request({
    url: '/interfaces/v1/disposal/edit',
    method: 'post',
    data,
  })
}
// 删除处置
export async function delDisposeRecord(data: any) {
  return request({
    url: '/interfaces/v1/disposal/del',
    method: 'post',
    data,
  })
}
// 关联收费项目
export async function associationDisposeRecord(data: any) {
  return request({
    url: '/interfaces/v1/disposal/transfer',
    method: 'post',
    data,
  })
}
// 完成处置
export async function completeDisposeRecord(data: any) {
  return request({
    url: '/interfaces/v1/disposal/complete',
    method: 'post',
    data,
  })
}
// 申请划扣
export async function allocationDisposeRecord(data: any) {
  return request({
    url: '/interfaces/v1/disposal/allocation',
    method: 'post',
    data,
  })
}

