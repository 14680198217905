import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0c2731b0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "",
  style: {
    "height": "500px"
  }
};
const _hoisted_2 = {
  class: "articlesType-Container"
};
const _hoisted_3 = {
  class: "articlesTypeItem",
  style: {
    "width": "300px"
  }
};
const _hoisted_4 = {
  class: "articlesTypeItem",
  style: {
    "width": "300px"
  }
};
const _hoisted_5 = {
  class: "articlesTypeItem",
  style: {
    "width": "calc(100% - 600px)"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TypeLevel = _resolveComponent("TypeLevel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_TypeLevel, {
    levelName: "一级来源",
    treeData: _ctx.treeData,
    pid: "0",
    id: _ctx.idLevel1,
    level: "idLevel1",
    showOperat: _ctx.showOperat1,
    onSelectPatientOrigin: _ctx.selectPatientOrigin,
    onHandleAddPatientOrigin: _ctx.getTreeDataLevel1
  }, null, 8, ["treeData", "id", "showOperat", "onSelectPatientOrigin", "onHandleAddPatientOrigin"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_TypeLevel, {
    levelName: "二级来源",
    treeData: _ctx.treeDataLevel2,
    pid: _ctx.idLevel1,
    id: _ctx.idLevel2,
    level: "idLevel2",
    showOperat: _ctx.showOperat2,
    onSelectPatientOrigin: _ctx.selectPatientOrigin,
    onHandleAddPatientOrigin: _ctx.getTreeDataLevel2
  }, null, 8, ["treeData", "pid", "id", "showOperat", "onSelectPatientOrigin", "onHandleAddPatientOrigin"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_TypeLevel, {
    levelName: "三级来源",
    treeData: _ctx.treeDataLevel3,
    pid: _ctx.idLevel2,
    id: _ctx.idLevel3,
    level: "idLevel3",
    showOperat: _ctx.showOperat3,
    onSelectPatientOrigin: _ctx.selectPatientOrigin,
    onHandleAddPatientOrigin: _ctx.getTreeDataLevel3
  }, null, 8, ["treeData", "pid", "id", "showOperat", "onSelectPatientOrigin", "onHandleAddPatientOrigin"])])])]);
}