import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1e71f97f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "avatar-uploader"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "operate",
  style: {
    "display": "none"
  }
};
const _hoisted_4 = {
  class: "bottomBtns",
  style: {
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Delete = _resolveComponent("Delete");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_Plus = _resolveComponent("Plus");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "formRef",
    model: _ctx.form,
    rules: _ctx.rules,
    "label-width": "80px",
    style: {
      "width": "100%",
      "height": "250px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "头像",
          prop: "cert_photo"
        }, {
          default: _withCtx(() => [_ctx.form.avatar ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
            src: _ctx.form.avatar,
            class: "avatar"
          }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_icon, {
            class: "deleteBtn",
            title: "删除",
            onClick: _cache[0] || (_cache[0] = $event => _ctx.deleteImages())
          }, {
            default: _withCtx(() => [_createVNode(_component_Delete)]),
            _: 1
          })])])) : (_openBlock(), _createBlock(_component_el_upload, {
            key: 1,
            class: "avatar-uploader",
            ref: "upload",
            "before-upload": _ctx.beforeUpload
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon, {
              class: "avatar-uploader-icon"
            }, {
              default: _withCtx(() => [_createVNode(_component_Plus)]),
              _: 1
            })]),
            _: 1
          }, 8, ["before-upload"]))]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "姓名",
          prop: "name"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.form.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.form.name = $event),
            type: "text"
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "手机号",
          prop: "mobile"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.form.mobile,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.form.mobile = $event),
            type: "text",
            minlength: "11",
            maxlength: "11"
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "身份证号",
          prop: "ID_number"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.form.ID_number,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.form.ID_number = $event),
            type: "text",
            minlength: "18",
            maxlength: "18"
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "有效期",
          prop: "validity"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            style: {
              "width": "100%"
            },
            modelValue: _ctx.form.validity,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.form.validity = $event),
            format: "YYYY-MM-DD",
            placeholder: "请选择有效期",
            type: "date",
            "value-format": "YYYY-MM-DD"
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "发证机关",
          prop: "issuing_authority"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.form.issuing_authority,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.form.issuing_authority = $event),
            type: "text"
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
      class: "text-btn",
      type: "primary",
      onClick: _ctx.handleAddData
    }, {
      default: _withCtx(() => [_createTextVNode(" 保存 ")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      class: "text-btn",
      onClick: _ctx.handleCancel
    }, {
      default: _withCtx(() => [_createTextVNode("取消")]),
      _: 1
    }, 8, ["onClick"])])]),
    _: 1
  }, 8, ["model", "rules"]);
}