const OSS = require('ali-oss');
const {
  accessKeyId,
  accessKeySecret,
  region,
  bucket,
} = require('../config/aliOss.config')

import { ElMessage } from 'element-plus'
import { useUserStore } from '@/store/modules/user'
import { parseTime } from '@/utils/index'

const client = new OSS({
  accessKeyId,
  accessKeySecret,
  region,
  bucket,
});

export const getYmd = () => {
  return parseTime(Date.now(), '{y}{m}{d}')
}

export const fileExt = ['.png', '.jpg', '.jpeg']
export const fileSize = 20

export const fileUpload = async (file: any) => {
  const getFileExt = (fileName: string) => {
    return  fileName.substring(fileName.lastIndexOf("."))
  }

  // 限制上传类型
  const fileExtFlag = fileExt.includes(getFileExt(file.name))      
  if (!fileExtFlag) {       
    ElMessage({ message: `上传文件类型只能是 ${fileExt.join(' ')} 格式!`, type: 'error' }) 
    return 
  }

  // //限制的上限      
  // const fileSizeFlag = (file.size / 1024 / 1024) < fileSize;
  // if (!fileSizeFlag) {        
  //   ElMessage({ message: `'上传文件大小不能超过 ${ fileSize } MB!'`, type: 'error' })
  //   return 
  // }
  
  if(fileExtFlag) {
    return await handleUpload(file);
  }
}

export const handleUpload = async (file: any) => {
  var obj = file.name
  var index = obj.lastIndexOf(".") 
  obj = obj.substring(index,obj.length) 
  let objName = getFileNameUUID()

  // 文件路径：商户ID+日期
  const { sId } = useUserStore()
  return await put(`${ sId }/${ getYmd() }/${objName}`, file)
}

export const put = async (fileName: any, file: any) => {
  try {    
    return await client.put(`${fileName}`, file)
  } catch (e) {    
    console.log(e)  
  }
}
 
// 上传成功之后，转换真实的地址
export const signatureUrl= async (fileName: string) => {    
  try {        
    return await client.signatureUrl(`${fileName}`)
  } catch (e) {    
    console.log(e)  
  }
}
 
export const getFileNameUUID = () => {  
  function rx() {    
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)  
  }  
  return `${+new Date()}_${rx()}${rx()}`
}

