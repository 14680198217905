import { defineComponent } from 'vue';
const {
  fildbase
} = require('@/config');
import { encryption } from '@/utils/validate';
import { getWarehouseListNoAuth } from '@/api/inventory';
export default defineComponent({
  created() {},
  data() {
    return {
      delConfirmMsg: '删除该数据, 是否继续?',
      delSuccessMsg: '删除成功',
      editSuccessMsg: '修改成功'
    };
  },
  methods: {
    tableRowClassName({
      row,
      rowIndex
    }) {
      row.index = rowIndex;
    },
    // TODO:
    async getPatientList() {
      // const { data } = await getUserList(encryption({
      //   position_id: '2'
      // }))
      // this.vabtable.form.filter((item) => {
      //   return item.key == 'patient_id'
      // })[0].data = data || []

      this.patientList = [{
        id: 1,
        user_name: '测试'
      }];
    },
    async handleDataExport({
      exportRequest,
      params,
      fileName
    }) {
      const {
        data
      } = await exportRequest(encryption(params));
      this.download(fildbase + data, fileName || data);
    },
    download(url, fileName) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.setAttribute('target', '_blank');
      fileName && a.setAttribute('download', fileName);
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
});