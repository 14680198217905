import request from '@/utils/request'

// 获取配置大类
export function getProjectType(data: any) {
  return request({
    url: '/interfaces/v1/getBasicProjects',
    method: 'get',
    params: data,
  })
}
// 获取项目列表
export function getProjectList(data: any) {
  return request({
    url: '/interfaces/v1/basic/index',
    method: 'get',
    params: data,
  })
}
// 添加项目
export function addProject(data: any) {
  return request({
    url: '/interfaces/v1/basic/add',
    method: 'POST',
    data: data,
  })
}
// 修改项目
export function editProject(data: any) {
  return request({
    url: '/interfaces/v1/basic/edit',
    method: 'POST',
    data: data,
  })
}
// 删除项目
export function deleteProject(data: any) {
  return request({
    url: '/interfaces/v1/basic/del',
    method: 'POST',
    data: data,
  })
}
// 排序项目
export function sortProject(data: any) {
  return request({
    url: '/interfaces/v1/basic/sort',
    method: 'POST',
    data: data,
  })
}


// 获取诊室列表
export function getConsultingRoomList(data: any) {
  return request({
    url: '/interfaces/v1/consulting/index',
    method: 'get',
    params: data,
  })
}
// 获取诊室列表(无权限限制)
export function getDataConsultingRoom(data: any) {
  return request({
    url: '/interfaces/v1/consulting/getdata',
    method: 'get',
    params: data,
  })
}
// 添加诊室
export function addConsultingRoom(data: any) {
  return request({
    url: '/interfaces/v1/consulting/add',
    method: 'POST',
    data: data,
  })
}
// 修改诊室
export function editConsultingRoom(data: any) {
  return request({
    url: '/interfaces/v1/consulting/edit',
    method: 'POST',
    data: data,
  })
}
// 删除诊室
export function deleteConsultingRoom(data: any) {
  return request({
    url: '/interfaces/v1/consulting/del',
    method: 'POST',
    data: data,
  })
}
// 排序诊室
export function sortConsultingRoom(data: any) {
  return request({
    url: '/interfaces/v1/consulting/sort',
    method: 'POST',
    data: data,
  })
}


// 获取系统标签列表
export function getSystemTagList(data: any) {
  return request({
    url: '/interfaces/v1/systemTag/index',
    method: 'get',
    params: data,
  })
}
// 获取系统标签列表[无权限]
export function getSystemTagListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/systemTag/getdata',
    method: 'get',
    params: data,
  })
}
// 排序系统标签
export function sortSystemTag(data: any) {
  return request({
    url: '/interfaces/v1/systemTag/edit',
    method: 'POST',
    data: data,
  })
}


// 获取标签列表
export function getTagList(data: any) {
  return request({
    url: '/interfaces/v1/patientTag/index',
    method: 'get',
    params: data,
  })
}
// 添加标签
export function addTag(data: any) {
  return request({
    url: '/interfaces/v1/patientTag/add',
    method: 'POST',
    data: data,
  })
}
// 修改项目
export function editTag(data: any) {
  return request({
    url: '/interfaces/v1/patientTag/edit',
    method: 'POST',
    data: data,
  })
}
// 删除项目
export function deleteTag(data: any) {
  return request({
    url: '/interfaces/v1/patientTag/del',
    method: 'POST',
    data: data,
  })
}


// 获取自由项
export function getfreeFieldConfig(data: any) {
  return request({
    url: '/interfaces/v1/freeFieldConfig/index',
    method: 'get',
    params: data,
  })
}
// 自由项设置是否启用
export function setfreeFieldConfig(data: any) {
  return request({
    url: '/interfaces/v1/freeFieldConfig/setUse',
    method: 'POST',
    data: data,
  })
}
// 自由项设置 全部 启用禁用
export function setAllFreeFieldConfig(data: any) {
  return request({
    url: '/interfaces/v1/freeFieldConfig/setAllUse',
    method: 'POST',
    data: data,
  })
}
//获取启动的自由项(无权限限制)
export function getOpenFieldConfig(data: any) {
  return request({
    url: '/interfaces/v1/freeFieldConfig/getOpen',
    method: 'get',
    params: data,
  })
}
// 自由项设置是否启用
export function setUseFreeFieldConfig(data: any) {
  return request({
    url: '/interfaces/v1/freeFieldConfig/setUse',
    method: 'POST',
    data: data,
  })
}
// 自由项修改
export function editFreeFieldConfig(data: any) {
  return request({
    url: '/interfaces/v1/freeFieldConfig/edit',
    method: 'POST',
    data: data,
  })
}
// 获取支出设置
export function getExpend(data: any) {
  return request({
    url: '/interfaces/v1/expend/index',
    method: 'get',
    params: data,
  })
}
// 添加支出设置
export function addExpend(data: any) {
  return request({
    url: '/interfaces/v1/expend/add',
    method: 'post',
    data: data,
  })
}
// 修改支出设置
export function editExpend(data: any) {
  return request({
    url: '/interfaces/v1/expend/edit',
    method: 'post',
    data: data,
  })
}
// 支出设置排序设置
export function sortSave(data: any) {
  return request({
    url: '/interfaces/v1/expend/sort',
    method: 'post',
    data: data,
  })
}
// 删除支出设置
export function delExpend(data: any) {
  return request({
    url: '/interfaces/v1/expend/del',
    method: 'post',
    data: data,
  })
}
// 获取支出设置(无权限限制)
export function getAllExpend(data: any) {
  return request({
    url: '/interfaces/v1/expend/getData',
    method: 'get',
    params: data,
  })
}
//获取病例设置详情
export function getCaseSet(data: any) {
  return request({
    url: '/interfaces/v1/caseSet/index',
    method: 'GET',
    params: data,
  })
}
//编辑病例设置
export function setCaseSet(data: any) {
  return request({
    url: '/interfaces/v1/caseSet/set',
    method: 'post',
    data: data,
  })
}
// 获取模板目录
export function getcase(data: any) {
  return request({
    url: '/interfaces/v1/case/index',
    method: 'GET',
    params: data,
  })
}
// 添加模板目录
export function addcase(data: any) {
  return request({
    url: '/interfaces/v1/case/add',
    method: 'post',
    data: data,
  })
}
// 修改模板目录
export function editcase(data: any) {
  return request({
    url: '/interfaces/v1/case/edit',
    method: 'post',
    data: data,
  })
}
// 删除模板目录
export function delcase(data: any) {
  return request({
    url: '/interfaces/v1/case/del',
    method: 'post',
    data: data,
  })
}
// 获取模板详情
export function infocase(data: any) {
  return request({
    url: '/interfaces/v1/case/info',
    method: 'GET',
    params: data,
  })
}
// 添加模板
export function addTemplate(data: any) {
  return request({
    url: '/interfaces/v1/case/addTemplate',
    method: 'post',
    data: data,
  })
}
// 修改模板
export function editTemplate(data: any) {
  return request({
    url: '/interfaces/v1/case/editTemplate',
    method: 'post',
    data: data,
  })
}
// 删除模板
export function delTemplate(data: any) {
  return request({
    url: '/interfaces/v1/case/delTemplate',
    method: 'post',
    data: data,
  })
}
// 转移模板
export function moveTemplate(data: any) {
  return request({
    url: '/interfaces/v1/case/moveTemplate',
    method: 'post',
    data: data,
  })
}
// 获取词条
export function getentry(data: any) {
  return request({
    url: '/interfaces/v1/entry/index',
    method: 'get',
    params: data,
  })
}
// 获取词条
export function addentry(data: any) {
  return request({
    url: '/interfaces/v1/entry/add',
    method: 'POST',
    data: data,
  })
}
//修改词条
export function editentry(data: any) {
  return request({
    url: '/interfaces/v1/entry/edit',
    method: 'POST',
    data: data,
  })
}
//删除词条
export function delentry(data: any) {
  return request({
    url: '/interfaces/v1/entry/del',
    method: 'POST',
    data: data,
  })
}
//保存词条
export function entrySave(data: any) {
  return request({
    url: '/interfaces/v1/entry/save',
    method: 'POST',
    data: data,
  })
}
// 正畸计划设置
// 获取诊断配置信息
export function getdiagnostic(data: any) {
  return request({
    url: '/interfaces/v1/diagnostic/index',
    method: 'GET',
    params: data,
  })
}
// 诊断配置目录添加
export function adddiagnostic(data: any) {
  return request({
    url: '/interfaces/v1/diagnostic/add',
    method: 'POST',
    data: data,
  })
}
// 诊断配置目录修改
export function editdiagnostic(data: any) {
  return request({
    url: '/interfaces/v1/diagnostic/edit',
    method: 'POST',
    data: data,
  })
}
// 诊断配置目录删除
export function deldiagnostic(data: any) {
  return request({
    url: '/interfaces/v1/diagnostic/del',
    method: 'POST',
    data: data,
  })
}
// 保存诊断配置
export function savediagnostic(data: any) {
  return request({
    url: '/interfaces/v1/diagnostic/save',
    method: 'POST',
    data: data,
  })
}
//  获取知情同意书
export function getinformed(data: any) {
  return request({
    url: '/interfaces/v1/informed/index',
    method: 'GET',
    params: data,
  })
}
//  添加知情同意书
export function addinformed(data: any) {
  return request({
    url: '/interfaces/v1/informed/add',
    method: 'POST',
    data: data,
  })
}
//  修改知情同意书
export function editinformed(data: any) {
  return request({
    url: '/interfaces/v1/informed/edit',
    method: 'POST',
    data: data,
  })
}

//  删除知情同意书
export function delinformed(data: any) {
  return request({
    url: '/interfaces/v1/informed/del',
    method: 'POST',
    data: data,
  })
}
//  知情同意书排序设置
export function sortinformed(data: any) {
  return request({
    url: '/interfaces/v1/informed/sort',
    method: 'POST',
    data: data,
  })
}
//  获取治疗步骤
export function getstep(data: any) {
  return request({
    url: '/interfaces/v1/step/index',
    method: 'GET',
    params: data,
  })
}
//  添加治疗步骤
export function addstep(data: any) {
  return request({
    url: '/interfaces/v1/step/add',
    method: 'POST',
    data: data,
  })
}

//  修改治疗步骤
export function editstep(data: any) {
  return request({
    url: '/interfaces/v1/step/edit',
    method: 'POST',
    data: data,
  })
}
//  删除治疗步骤
export function delstep(data: any) {
  return request({
    url: '/interfaces/v1/step/del',
    method: 'POST',
    data: data,
  })
}
// 正畸过程设置
//  获取基本配置
export function getorthodonticsSet(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsSet/index',
    method: 'get',
    params: data,
  })
}
//  编辑基本配置
export function setorthodonticsSet(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsSet/set',
    method: 'post',
    data: data,
  })
}
//  获取模板目录
export function getorthodonticsTemplate(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsTemplate/index',
    method: 'get',
    params: data,
  })
}
//  创建模板目录
export function addorthodonticsTemplate(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsTemplate/add',
    method: 'post',
    data: data,
  })
}
//  修改模板目录
export function editorthodonticsTemplate(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsTemplate/edit',
    method: 'post',
    data: data,
  })
}
//  删除模板目录
export function delorthodonticsTemplate(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsTemplate/del',
    method: 'post',
    data: data,
  })
}
// 获取模板详情
export function infoorthodonticsTemplate(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsTemplate/info',
    method: 'get',
    params: data,
  })
}
// 添加模板
export function addTemplateOrthodon(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsTemplate/addTemplate',
    method: 'post',
    data: data,
  })
}
// 修改模板
export function editTemplateOrthodon(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsTemplate/editTemplate',
    method: 'post',
    data: data,
  })
}
// 删除模板
export function delTemplateOrthodon(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsTemplate/delTemplate',
    method: 'post',
    data: data,
  })
}
// 转移模板
export function moveTemplateOrthodon(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsTemplate/moveTemplate',
    method: 'post',
    data: data,
  })
}
// 获取词条
export function getorthodonticsEntry(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsEntry/index',
    method: 'get',
    params: data,
  })
}
// 添加词条
export function addorthodonticsEntry(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsEntry/add',
    method: 'post',
    data: data,
  })
}
// 修改词条
export function editorthodonticsEntry(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsEntry/edit',
    method: 'post',
    data: data,
  })
}
// 删除词条
export function delorthodonticsEntry(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsEntry/del',
    method: 'post',
    data: data,
  })
}
// 保存词条列表
export function saveorthodonticsEntry(data: any) {
  return request({
    url: '/interfaces/v1/orthodonticsEntry/save',
    method: 'post',
    data: data,
  })
}



// 审批设置列表
export function getApproveSettingList(data: any) {
  return request({
    url: '/interfaces/v1/examine/index',
    method: 'get',
    params: data,
  })
}
// 添加审批设置
export function addApproveSetting(data: any) {
  return request({
    url: '/interfaces/v1/examine/add',
    method: 'POST',
    data: data,
  })
}
// 修改审批设置
export function editApproveSetting(data: any) {
  return request({
    url: '/interfaces/v1/examine/edit',
    method: 'POST',
    data: data,
  })
}
// 修改审批设置状态
export function editApproveSettingStatus(data: any) {
  return request({
    url: '/interfaces/v1/examine/status',
    method: 'POST',
    data: data,
  })
}
// 删除审批设置
export function deleteApproveSetting(data: any) {
  return request({
    url: '/interfaces/v1/examine/del',
    method: 'POST',
    data: data,
  })
}

// 获取业务规则
export function getBusinessRules(data: any) {
  return request({
    url: '/interfaces/v1/stockRules/index',
    method: 'get',
    params: data,
  })
}
// 设置业务规则
export function setBusinessRules(data: any) {
  return request({
    url: '/interfaces/v1/stockRules/set',
    method: 'POST',
    data: data,
  })
}


// 获取患者必填项
export function getPatientFields(data: any) {
  return request({
    url: '/interfaces/v1/requiredFields/index',
    method: 'get',
    params: data,
  })
}
// 设置患者必填项
export function setPatientFields(data: any) {
  return request({
    url: '/interfaces/v1/requiredFields/set',
    method: 'POST',
    data: data,
  })
}


// 获取诊所信息
export function getClinicInfo(data: any) {
  return request({
    url: '/interfaces/v1/clinic/info',
    method: 'get',
    params: data,
  })
}
// 设置诊所信息
export function setClinicInfo(data: any) {
  return request({
    url: '/interfaces/v1/clinic/set',
    method: 'POST',
    data: data,
  })
}


// 获取班次配置列表
export function getSchedulingSettingList(data: any) {
  return request({
    url: '/interfaces/v1/classes/index',
    method: 'get',
    params: data,
  })
}
export function getSchedulingSettingListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/classes/getdata',
    method: 'get',
    params: data,
  })
}
export function addSchedulingSetting(data: any) {
  return request({
    url: '/interfaces/v1/classes/add',
    method: 'POST',
    data: data,
  })
}
export function editSchedulingSetting(data: any) {
  return request({
    url: '/interfaces/v1/classes/edit',
    method: 'POST',
    data: data,
  })
}
export function delSchedulingSetting(data: any) {
  return request({
    url: '/interfaces/v1/classes/del',
    method: 'POST',
    data: data,
  })
}


// 获取随访内容列表
export function getFollowupContentList(data: any) {
  return request({
    url: '/interfaces/v1/followupContent/index',
    method: 'get',
    params: data,
  })
}
export function getFollowupContentListNoAuth(data: any) {
  return request({
    url: 'interfaces/v1/followupContent/getdata',
    method: 'get',
    params: data,
  })
}
// 添加随访内容
export function addFollowupContent(data: any) {
  return request({
    url: '/interfaces/v1/followupContent/add',
    method: 'POST',
    data: data,
  })
}
// 修改随访内容
export function editFollowupContent(data: any) {
  return request({
    url: '/interfaces/v1/followupContent/edit',
    method: 'POST',
    data: data,
  })
}
// 删除随访内容
export function deleteFollowupContent(data: any) {
  return request({
    url: '/interfaces/v1/followupContent/del',
    method: 'POST',
    data: data,
  })
}
// 排序随访内容
export function sortFollowupContent(data: any) {
  return request({
    url: '/interfaces/v1/followupContent/sort',
    method: 'POST',
    data: data,
  })
}


// 获取随访结果列表
export function getFollowupResultList(data: any) {
  return request({
    url: '/interfaces/v1/FollowupResult/index',
    method: 'get',
    params: data,
  })
}
export function getFollowupResultListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/FollowupResult/getdata',
    method: 'get',
    params: data,
  })
}
// 添加随访结果
export function addFollowupResult(data: any) {
  return request({
    url: '/interfaces/v1/FollowupResult/add',
    method: 'POST',
    data: data,
  })
}
// 修改随访结果
export function editFollowupResult(data: any) {
  return request({
    url: '/interfaces/v1/FollowupResult/edit',
    method: 'POST',
    data: data,
  })
}
// 删除随访结果
export function deleteFollowupResult(data: any) {
  return request({
    url: '/interfaces/v1/FollowupResult/del',
    method: 'POST',
    data: data,
  })
}
// 排序随访结果
export function sortFollowupResult(data: any) {
  return request({
    url: '/interfaces/v1/FollowupResult/sort',
    method: 'POST',
    data: data,
  })
}


// 获取随访模板列表
export function getFollowupTempList(data: any) {
  return request({
    url: '/interfaces/v1/followup/index',
    method: 'get',
    params: data,
  })
}
// 获取随访模板列表[无权限]
export function getFollowupTempListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/followup/getdata',
    method: 'get',
    params: data,
  })
}
// 获取随访模板详情
export function getFollowupTempDetail(data: any) {
  return request({
    url: '/interfaces/v1/followup/getInfo',
    method: 'get',
    params: data,
  })
}
// 添加随访模板
export function addFollowupTemp(data: any) {
  return request({
    url: '/interfaces/v1/followup/add',
    method: 'POST',
    data: data,
  })
}
// 修改随访模板
export function editFollowupTemp(data: any) {
  return request({
    url: '/interfaces/v1/followup/edit',
    method: 'POST',
    data: data,
  })
}
// 修改状态随访模板
export function editStatusFollowupTemp(data: any) {
  return request({
    url: '/interfaces/v1/followup/setStatus',
    method: 'POST',
    data: data,
  })
}
// 删除随访模板
export function deleteFollowupTemp(data: any) {
  return request({
    url: '/interfaces/v1/followup/del',
    method: 'POST',
    data: data,
  })
}
// 排序随访模板
export function sortFollowupTemp(data: any) {
  return request({
    url: '/interfaces/v1/followup/sort',
    method: 'POST',
    data: data,
  })
}


// 获取随访规则列表
export function getFollowupRulesList(data: any) {
  return request({
    url: '/interfaces/v1/followupAutoRule/index',
    method: 'get',
    params: data,
  })
}
// 获取随访规则详情
export function getFollowupRulesDetail(data: any) {
  return request({
    url: '/interfaces/v1/followupAutoRule/getInfo',
    method: 'get',
    params: data,
  })
}
// 添加随访规则
export function addFollowupRules(data: any) {
  return request({
    url: '/interfaces/v1/followupAutoRule/add',
    method: 'POST',
    data: data,
  })
}
// 修改随访规则
export function editFollowupRules(data: any) {
  return request({
    url: '/interfaces/v1/followupAutoRule/edit',
    method: 'POST',
    data: data,
  })
}
// 修改状态随访规则
export function editStatusFollowupRules(data: any) {
  return request({
    url: '/interfaces/v1/followupAutoRule/setStatus',
    method: 'POST',
    data: data,
  })
}
// 删除随访规则
export function deleteFollowupRules(data: any) {
  return request({
    url: '/interfaces/v1/followupAutoRule/del',
    method: 'POST',
    data: data,
  })
}
// 排序随访规则
export function sortFollowupRules(data: any) {
  return request({
    url: '/interfaces/v1/followupAutoRule/sort',
    method: 'POST',
    data: data,
  })
}


// 回访设置
export function setFollowVisitConfig(data: any) {
  return request({
    url: '/interfaces/v1/followVisit/set',
    method: 'POST',
    data: data,
  })
}
export function getFollowVisitConfig(data: any) {
  return request({
    url: '/interfaces/v1/followVisit/get',
    method: 'get',
    params: data,
  })
}




// 收费项目必填项设置
export function getFeeItemsFieldsList(data: any) {
  return request({
    url: '/interfaces/v1/payServiceRequired/index',
    method: 'get',
    params: data,
  })
}
export function getFeeItemsFieldsListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/payServiceRequired/getData',
    method: 'get',
    params: data,
  })
}
export function setFeeItemsFields(data: any) {
  return request({
    url: '/interfaces/v1/payServiceRequired/set',
    method: 'post',
    data,
  })
}


// 获取费用类型列表
export function getFeeTypeSettingList(data: any) {
  return request({
    url: '/interfaces/v1/payServiceType/index',
    method: 'get',
    params: data,
  })
}
// 获取费用类型列表[无权限]
export function getFeeTypeSettingListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/payServiceType/getdata',
    method: 'get',
    params: data,
  })
}
// 添加费用类型
export function addFeeTypeSetting(data: any) {
  return request({
    url: '/interfaces/v1/payServiceType/add',
    method: 'POST',
    data: data,
  })
}
// 修改费用类型
export function editFeeTypeSetting(data: any) {
  return request({
    url: '/interfaces/v1/payServiceType/edit',
    method: 'POST',
    data: data,
  })
}
// 删除费用类型
export function deleteFeeTypeSetting(data: any) {
  return request({
    url: '/interfaces/v1/payServiceType/del',
    method: 'POST',
    data: data,
  })
}
// 排序费用类型
export function sortFeeTypeSetting(data: any) {
  return request({
    url: '/interfaces/v1/payServiceType/sort',
    method: 'POST',
    data: data,
  })
}


// 获取收费项目类别列表
export function getFeeItemTypeList(data: any) {
  return request({
    url: '/interfaces/v1/payServiceCategory/index',
    method: 'get',
    params: data,
  })
}
// 获取收费项目类别列表[无权限]
export function getFeeItemTypeListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/payServiceCategory/getdata',
    method: 'get',
    params: data,
  })
}
// 添加收费项目类别
export function addFeeItemType(data: any) {
  return request({
    url: '/interfaces/v1/payServiceCategory/add',
    method: 'POST',
    data: data,
  })
}
// 修改收费项目类别
export function editFeeItemType(data: any) {
  return request({
    url: '/interfaces/v1/payServiceCategory/edit',
    method: 'POST',
    data: data,
  })
}
// 删除收费项目类别
export function deleteFeeItemType(data: any) {
  return request({
    url: '/interfaces/v1/payServiceCategory/del',
    method: 'POST',
    data: data,
  })
}
// 排序收费项目类别
export function sortFeeItemType(data: any) {
  return request({
    url: '/interfaces/v1/payServiceCategory/sort',
    method: 'POST',
    data: data,
  })
}


// 获取收费项目列表
export function getFeeItemList(data: any) {
  return request({
    url: '/interfaces/v1/payService/index',
    method: 'get',
    params: data,
  })
}
// 获取收费项目列表[无权限]
export function getFeeItemListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/payService/getdata',
    method: 'get',
    params: data,
  })
}
// 获取收费项目详情[无权限]
export function getFeeItemDetailNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/payService/getInfo',
    method: 'get',
    params: data,
  })
}
// 添加收费项目
export function addFeeItem(data: any) {
  return request({
    url: '/interfaces/v1/payService/add',
    method: 'POST',
    data: data,
  })
}
// 修改收费项目
export function editFeeItem(data: any) {
  return request({
    url: '/interfaces/v1/payService/edit',
    method: 'POST',
    data: data,
  })
}
// 启禁用收费项目
export function setStatusFeeItem(data: any) {
  return request({
    url: '/interfaces/v1/payService/status',
    method: 'POST',
    data: data,
  })
}


// 获取划扣方案列表
export function getDeductPlanList(data: any) {
  return request({
    url: '/interfaces/v1/drawSolution/index',
    method: 'get',
    params: data,
  })
}
// 获取划扣方案列表[无权限]
export function getDeductPlanNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/drawSolution/getdata',
    method: 'get',
    params: data,
  })
}
// 添加划扣方案
export function addDeductPlan(data: any) {
  return request({
    url: '/interfaces/v1/drawSolution/add',
    method: 'POST',
    data: data,
  })
}
// 修改划扣方案
export function editDeductPlan(data: any) {
  return request({
    url: '/interfaces/v1/drawSolution/edit',
    method: 'POST',
    data: data,
  })
}
// 删除划扣方案
export function deleteDeductPlan(data: any) {
  return request({
    url: '/interfaces/v1/drawSolution/del',
    method: 'POST',
    data: data,
  })
}


// 获取划扣方案项目列表
export function getDeductPlanItemList(data: any) {
  return request({
    url: '/interfaces/v1/drawSolution/getChargeItem',
    method: 'get',
    params: data,
  })
}
// 添加划扣方案项目
export function addDeductPlanItem(data: any) {
  return request({
    url: '/interfaces/v1/drawSolution/saveChargeItem',
    method: 'POST',
    data: data,
  })
}
// 删除划扣方案项目
export function deleteDeductPlanItem(data: any) {
  return request({
    url: '/interfaces/v1/drawSolution/delChargeItem',
    method: 'POST',
    data: data,
  })
}


// 获取收费组合列表
export function getFeeCombineList(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/index',
    method: 'get',
    params: data,
  })
}
// 获取收费组合列表[无权限]
export function getFeeCombineListNoAuth(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/getData',
    method: 'get',
    params: data,
  })
}
// 获取收费组合详情
export function getFeeCombineDetail(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/getChargeAssembly',
    method: 'get',
    params: data,
  })
}
// 添加收费组合
export function addFeeCombine(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/add',
    method: 'POST',
    data: data,
  })
}
// 修改收费组合
export function editFeeCombine(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/edit',
    method: 'POST',
    data: data,
  })
}
// 删除收费组合
export function deleteFeeCombine(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/del',
    method: 'POST',
    data: data,
  })
}



// 添加收费组合项目
export function addFeeCombineItem(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/addChargeAssembly',
    method: 'POST',
    data: data,
  })
}
// 修改收费组合项目
export function editFeeCombineItem(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/editChargeAssembly',
    method: 'POST',
    data: data,
  })
}
// 移动收费组合项目
export function moveFeeCombineItem(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/transfer',
    method: 'POST',
    data: data,
  })
}
// 删除收费组合项目
export function deleteFeeCombineItem(data: any) {
  return request({
    url: '/interfaces/v1/chargeAssembly/delChargeAssembly',
    method: 'POST',
    data: data,
  })
}


/**
 * 支付方式分类
 */
export function getPayTypeList(data: any) {
  return request({
    url: '/interfaces/v1/payType/getType',
    method: 'get',
    params: data,
  })
}
/**
 * 支付方式列表
 */
export function getPayTypeMethodsList(data: any) {
  return request({
    url: '/interfaces/v1/payType/index',
    method: 'get',
    params: data,
  })
}
export function addPayTypeMethod(data: any) {
  return request({
    url: '/interfaces/v1/payType/add',
    method: 'POST',
    data: data,
  })
}
export function editPayTypeMethod(data: any) {
  return request({
    url: '/interfaces/v1/payType/edit',
    method: 'POST',
    data: data,
  })
}
export function deletePayTypeMethod(data: any) {
  return request({
    url: '/interfaces/v1/payType/del',
    method: 'POST',
    data: data,
  })
}


