import { storage, tokenTableName } from '@/config'
import cookie from 'js-cookie'

/**
 * @description 获取item
 */
export function getItem(key: string) {
  switch(storage) {
    case 'localStorage':
      return localStorage.getItem(key)
    case 'sessionStorage':
      return sessionStorage.getItem(key)
    case 'cookie':
      return cookie.get(key)
    default:
      return localStorage.getItem(key)
  }
}

/**
 * @description 存储item
 */
export function setItem(key: string, value: any) {
  switch(storage) {
    case 'localStorage':
      return localStorage.setItem(key, value)
    case 'sessionStorage':
      return sessionStorage.setItem(key, value)
    case 'cookie':
      return cookie.set(key, value)
    default:
      return localStorage.setItem(key, value)
  }
}

/**
 * @description 移除item
 */
export function removeItem(key: string) {
  switch(storage) {
    case 'localStorage':
      return localStorage.removeItem(key)
    case 'sessionStorage':
      return sessionStorage.removeItem(key)
    case 'cookie':
      return cookie.remove(key)
    default:
      return localStorage.removeItem(key)
  }
}

