import { defineComponent } from 'vue';
import { encryption } from '@/utils/validate';
import { getPatientOriginTree } from '@/api/patient';
import TypeLevel from './typeLevel.vue';
export default defineComponent({
  name: 'patientOriginList',
  props: {},
  components: {
    TypeLevel
  },
  data() {
    return {
      treeData: [],
      treeDataLevel2: [],
      treeDataLevel3: [],
      idLevel1: '',
      idLevel2: '',
      idLevel3: '',
      showOperat1: false,
      showOperat2: false,
      showOperat3: false
    };
  },
  created() {
    this.getPatientOriginTree();
  },
  methods: {
    async getPatientOriginTree() {
      const {
        data
      } = await getPatientOriginTree(encryption({
        pid: 0
      }));
      this.treeData = data || [];
      this.$emit('handleRefresh');
    },
    selectPatientOrigin(id, level) {
      if (level == 'idLevel1') {
        this.idLevel1 = id;
        this.idLevel2 = '';
        this.idLevel3 = '';
        this.treeDataLevel2 = [];
        this.treeDataLevel3 = [];
        this.showOperat1 = true;
        this.showOperat2 = false;
        this.showOperat3 = false;
        this.getTreeDataLevel2();
      }
      if (level == 'idLevel2') {
        this.idLevel2 = id;
        this.idLevel3 = '';
        this.treeDataLevel3 = [];
        this.showOperat2 = true;
        this.showOperat3 = false;
        this.getTreeDataLevel3();
      }
      if (level == 'idLevel3') {
        this.idLevel3 = id;
        this.showOperat3 = true;
      }
    },
    async getTreeDataLevel1(refresh, isDel) {
      if (refresh) {
        await this.getPatientOriginTree();
        if (isDel) {
          this.showOperat1 = false;
          this.idLevel1 = '';
        }
      }
    },
    async getTreeDataLevel2(refresh, isDel) {
      if (refresh) {
        await this.getPatientOriginTree();
        if (isDel) {
          this.showOperat2 = false;
          this.idLevel2 = '';
        }
      }
      this.treeDataLevel2 = this.treeData.find(item => {
        return item.id == this.idLevel1;
      })?.['_children'];
    },
    async getTreeDataLevel3(refresh, isDel) {
      if (refresh) {
        await this.getTreeDataLevel2(true);
        if (isDel) {
          this.showOperat3 = false;
        }
      }
      this.treeDataLevel3 = this.treeDataLevel2.find(item => {
        return item.id == this.idLevel2;
      })?.['_children'];
    }
  }
});