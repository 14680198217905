import { defineComponent } from 'vue';
import { staticBase } from '@/config/setting.config';
import BasicComp from '@/views/Basic.vue';
import { addRelationship, editRelationship, getPatientDetail } from '@/api/patient';
import { getBasicItem } from '@/api/patient';
import { encryption } from '@/utils/validate';
import _ from 'lodash';
import { fileExt, fileUpload } from '@/utils/upload';
export default defineComponent({
  extends: BasicComp,
  props: {
    dataInfo: {
      type: Object,
      default: null
    }
  },
  components: {},
  data() {
    return {
      form: {},
      rules: {
        name: [{
          required: true,
          trigger: 'blur',
          message: '请输入姓名'
        }]
      },
      keywords: '',
      relationType: [],
      otherForm: null,
      staticBase,
      patientInfo: null,
      relevance_id: null,
      requiredFieldsList: [],
      addType: 1,
      showAddPatient: false
    };
  },
  created() {
    if (this.dataInfo) {
      this.form = {
        ...this.dataInfo
      };
    }
    this.getRelationType();
    // this.getRequiredFieldsList()
  },

  methods: {
    handleAddData() {
      let form = _.cloneDeep(this.form);
      this.$refs['formRef'].validate(async (valid, fields) => {
        if (valid) {
          this.$emit('handleAddPatient', form);
        }
      });
    },
    handleCancel() {
      this.$emit('handleCancelCallback');
    },
    async getRelationType() {
      const {
        code,
        data
      } = await getBasicItem(encryption({
        type: '关系类别'
      }));
      this.relationType = data || [];
    },
    handleChoosePatient(item) {
      this.patientInfo = {
        ...item
      };
      this.showPatientListFlag = false;
    },
    async getRequiredFieldsList() {
      const {
        code,
        data
      } = await getRequiredFieldsList(encryption({}));
      this.requiredFieldsList = data || [];
    },
    handleAddPatient(addType) {
      this.addType = addType;
      if (this.addType == 1) {
        this.showAddPatient = true;
      }
      if (this.addType == 2) {
        this.showAddTempInfo = true;
      }
    },
    async handleAddPatientCallback(flag, patientId) {
      this.showAddPatient = false;
      this.relevance_id = patientId;
      const {
        data
      } = await getPatientDetail(encryption({
        id: this.id
      }));
      this.patientInfo = data;
    },
    beforeUpload(file) {
      fileUpload(file).then(res => {
        this.form.avatar = res?.url;
      });
      return false;
    },
    deleteImages() {
      this.form.avatar = '';
    }
  }
});