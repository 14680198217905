import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/index';
import { staticBase } from '@/config/setting.config';
import BasicComp from '@/views/Basic.vue';
import { addRelationship, editRelationship, getPatientDetail } from '@/api/patient';
import { getBasicItem } from '@/api/patient';
import { encryption } from '@/utils/validate';
import _ from 'lodash';
import PatientList from './patientList.vue';
import { getRequiredFieldsList } from '@/api/patient';
// import AddPatient from '@/views/patient/components/add.vue'
import AddPatientNoFiling from './noFiling/add.vue';
export default defineComponent({
  name: 'relationshipAdd',
  extends: BasicComp,
  components: {
    PatientList,
    // AddPatient,
    AddPatientNoFiling
  },
  props: {
    patientId: {
      type: Number,
      default: null
    },
    dataInfo: {
      type: Object,
      default: null
    },
    viewFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        type: '手机号码属性'
      },
      rules: {
        relational_tag: [{
          required: true,
          trigger: 'blur',
          message: '请选择亲友关系'
        }]
      },
      keywords: '',
      relationType: [],
      otherForm: null,
      staticBase,
      patientInfo: null,
      relevance_id: null,
      requiredFieldsList: [],
      addType: 1,
      showAddPatient: false,
      showAddTempInfo: false,
      showPatientListFlag: false
    };
  },
  created() {
    if (this.dataInfo) {
      this.form = {
        ...this.dataInfo
      };
      if (this.form.type == 1) {
        this.getPatientDetail(this.dataInfo.relevance_id);
      } else {
        this.addType = 2;
        this.otherForm = {
          ...this.dataInfo
        };
      }
    }
    this.getRelationType();
    this.getRequiredFieldsList();
  },
  methods: {
    handleAddData() {
      let form = _.cloneDeep(this.form);
      form.patient_id = this.patientId;
      form.type = this.addType; // 1已录入患者 2未录入患者
      form.relevance_id = this.relevance_id || this.patientInfo?.id;
      if (this.addType == 2) {
        form.name = this.otherForm.name;
        form.ID_number = this.otherForm.ID_number;
        form.avatar = this.otherForm.avatar;
        form.issuing_authority = this.otherForm.issuing_authority;
        form.validity = this.otherForm.validity;
        form.mobile = this.otherForm.mobile;
      }
      if (this.addType == 1 && !this.patientInfo?.id || this.addType == 2 && !this.otherForm?.name) {
        ElMessage({
          message: '请选择亲友',
          type: 'error'
        });
        return;
      }
      this.$refs['formRef'].validate(async (valid, fields) => {
        if (valid) {
          if (form.id) {
            const {
              code,
              msg
            } = await editRelationship(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '修改成功',
                type: 'success'
              });
              this.$emit('handleAddCallback', 'save');
            }
          } else {
            const {
              code,
              msg
            } = await addRelationship(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '新建成功',
                type: 'success'
              });
              this.$emit('handleAddCallback', 'save');
            }
          }
        }
      });
    },
    handleCancel() {
      this.$emit('handleCancelCallback');
    },
    async getRelationType() {
      const {
        code,
        data
      } = await getBasicItem(encryption({
        type: '关系类别'
      }));
      this.relationType = data || [];
    },
    handleChoosePatient(item) {
      this.addType = 1;
      this.patientInfo = {
        ...item
      };
      this.showPatientListFlag = false;
    },
    async getRequiredFieldsList() {
      const {
        code,
        data
      } = await getRequiredFieldsList(encryption({}));
      this.requiredFieldsList = data || [];
    },
    handleAddPatient(addType) {
      this.addType = addType;
      if (this.addType == 1) {
        this.showAddPatient = true;
      }
      if (this.addType == 2) {
        this.showAddTempInfo = true;
      }
    },
    async handleAddPatientCallback(flag, patientId) {
      this.showAddPatient = false;
      this.relevance_id = patientId;
      if (patientId) {
        this.getPatientDetail(patientId);
      }
    },
    async getPatientDetail(patientId) {
      const {
        data
      } = await getPatientDetail(encryption({
        id: patientId
      }));
      this.patientInfo = data;
    },
    handleCancelCallback() {
      this.showAddTempInfo = false;
    },
    handleAddPatientTempCallback(otherForm) {
      this.otherForm = {
        ...otherForm
      };
      this.showAddTempInfo = false;
    },
    handleEditTempInfo() {
      this.showAddTempInfo = true;
    }
  }
});