import "core-js/modules/es.array.push.js";
import { __awaiter, __generator, __read, __spreadArray } from "tslib";
/**
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import { asyncRoutes, constantRoutes, resetRouter } from '@/router';
import { convertRouter, filterRoutes } from '@/utils/routes';
import { authentication, rolesControl } from '@/config';
import { isArray } from '@/utils/validate';
import { getList } from '@/api/menu';
import { gp } from '@gp';
export var useRoutesStore = defineStore('routes', {
  state: function () {
    return {
      /**
       * 一级菜单值
       */
      tab: {
        data: undefined
      },
      /**
       * 一级菜单
       */
      tabMenu: undefined,
      /**
       * 自定义激活菜单
       */
      activeMenu: {
        data: undefined
      },
      /**
       * 一级菜单
       */
      routes: []
    };
  },
  getters: {
    getTab: function (state) {
      return state.tab;
    },
    getTabMenu: function (state) {
      return state.tab.data ? state.routes.find(function (route) {
        return route.name === state.tab.data;
      }) : {
        meta: {
          title: ''
        },
        redirect: '404'
      };
    },
    getActiveMenu: function (state) {
      return state.activeMenu;
    },
    getRoutes: function (state) {
      return state.routes.filter(function (_route) {
        return _route.meta.hidden !== true;
      });
    },
    getPartialRoutes: function (state) {
      var _a;
      return ((_a = state.routes.find(function (route) {
        return route.name === state.tab.data;
      })) === null || _a === void 0 ? void 0 : _a.children) || [];
    }
  },
  actions: {
    clearRoutes: function () {
      this.routes = [];
    },
    /**
     * @description 多模式设置路由
     * @param mode
     * @returns
     */
    setRoutes: function (mode) {
      if (mode === void 0) {
        mode = 'none';
      }
      return __awaiter(this, void 0, void 0, function () {
        var routes, control, data, MenuData, accessRoutes;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              routes = __spreadArray([], __read(asyncRoutes), false);
              control = mode === 'visit' ? false : rolesControl;
              if (!(authentication === 'all')) return [3 /*break*/, 2];
              return [4 /*yield*/, getList()];
            case 1:
              data = _a.sent().data;
              MenuData = this.getMenuData(data);
              if (!isArray(MenuData)) gp.$baseMessage('路由格式返回有误！', 'error', 'vab-hey-message-error');
              if (MenuData[MenuData.length - 1].path !== '*') MenuData.push({
                path: '/:pathMatch(.*)*',
                // redirect: '/404',
                redirect: MenuData === null || MenuData === void 0 ? void 0 : MenuData[0].path,
                name: 'NotFound',
                meta: {
                  hidden: true
                }
              });
              routes = convertRouter(MenuData);
              _a.label = 2;
            case 2:
              accessRoutes = filterRoutes(__spreadArray(__spreadArray([], __read(constantRoutes), false), __read(routes), false), control);
              // 设置菜单所需路由
              this.routes = JSON.parse(JSON.stringify(accessRoutes));
              // 根据可访问路由重置Vue Router
              return [4 /*yield*/, resetRouter(accessRoutes)];
            case 3:
              // 根据可访问路由重置Vue Router
              _a.sent();
              return [2 /*return*/];
          }
        });
      });
    },

    changeMenuMeta: function (options) {
      function handleRoutes(routes) {
        return routes.map(function (route) {
          if (route.name === options.name) Object.assign(route.meta, options.meta);
          if (route.children && route.children.length) route.children = handleRoutes(route.children);
          return route;
        });
      }
      this.routes = handleRoutes(this.routes);
    },
    /**
     * @description 修改 activeName
     * @param activeMenu 当前激活菜单
     */
    changeActiveMenu: function (activeMenu) {
      this.activeMenu.data = activeMenu;
    },
    // 修改数据结构
    getMenuData: function (MenuDatas) {
      var _this = this;
      return MenuDatas.map(function (item) {
        var child = {
          name: item.name,
          path: item.route_url,
          component: item.redirect,
          children: item._children,
          parentId: item.pid,
          id: item.id,
          meta: {
            title: item.name,
            icon: item.icon,
            breadcrumbHidden: false
          }
        };
        if (child.component == 'level') {
          delete child.component;
        }
        if (item._children && item._children.length) {
          child.children = _this.getMenuData(item._children);
        } else {
          if (item.pid == '0') {
            child.component = 'Layout';
            child.meta.breadcrumbHidden = true;
            child.children = [{
              name: item.name,
              path: item.route_url,
              component: item.redirect,
              meta: {
                title: item.name,
                icon: item.icon,
                noColumn: true
              }
            }];
          } else {
            delete child.children;
          }
        }
        return child;
      });
    }
  }
});