import md5 from 'js-md5'
/**
 * @description 判读是否为外链
 * @param path
 * @returns {boolean}
 */
export function isExternal(path: string) {
  return /^(https?:|mailto:|tel:|\/\/)/.test(path)
}

/**
 * @description 校验密码是否小于6位
 * @param value
 * @returns {boolean}
 */
export function isPassword(value: string | any[]) {
  return value.length >= 6
}

/**
 * @description 判断是否为数字
 * @param value
 * @returns {boolean}
 */
export function isNumber(value: string) {
  const reg = /^[0-9]*$/
  return reg.test(value)
}

/**
 * @description 判断是否是名称
 * @param value
 * @returns {boolean}
 */
export function isName(value: string) {
  const reg = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/
  return reg.test(value)
}

/**
 * @description 判断是否为IP
 * @param ip
 * @returns {boolean}
 */
export function isIP(ip: string) {
  const reg =
    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
  return reg.test(ip)
}

/**
 * @description 判断是否是传统网站
 * @param url
 * @returns {boolean}
 */
export function isUrl(url: string) {
  const reg =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @description 判断是否是小写字母
 * @param value
 * @returns {boolean}
 */
export function isLowerCase(value: string) {
  const reg = /^[a-z]+$/
  return reg.test(value)
}

/**
 * @description 判断是否是大写字母
 * @param value
 * @returns {boolean}
 */
export function isUpperCase(value: string) {
  const reg = /^[A-Z]+$/
  return reg.test(value)
}

/**
 * @description 判断是否是大写字母开头
 * @param value
 * @returns {boolean}
 */
export function isAlphabets(value: string) {
  const reg = /^[A-Za-z]+$/
  return reg.test(value)
}

/**
 * @description 判断是否是字符串
 * @param value
 * @returns {boolean}
 */
export function isString(value: any) {
  return typeof value === 'string' || value instanceof String
}

/**
 * @description 判断是否是数组
 * @param arg
 */
export function isArray(arg: string | (string | number)[]) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @description 判断是否是端口号
 * @param value
 * @returns {boolean}
 */
export function isPort(value: string) {
  const reg =
    /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/
  return reg.test(value)
}

/**
 * @description 判断是否是手机号
 * @param value
 * @returns {boolean}
 */
export function isPhone(value: string) {
  const reg = /^1\d{10}$/
  return reg.test(value)
}

/**
 * @description 判断是否是身份证号(第二代)
 * @param value
 * @returns {boolean}
 */
export function isIdCard(value: string) {
  const reg =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  return reg.test(value)
}

/**
 * @description 判断是否是邮箱
 * @param value
 * @returns {boolean}
 */
export function isEmail(value: string) {
  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  return reg.test(value)
}

/**
 * @description 判断是否中文
 * @param value
 * @returns {boolean}
 */
export function isChina(value: string) {
  const reg = /^[\u4E00-\u9FA5]{2,4}$/
  return reg.test(value)
}

/**
 * @description 判断是否为空
 * @param value
 * @returns {boolean}
 */
export function isBlank(value: string | null) {
  return (
    value === null ||
    false ||
    value === '' ||
    value.trim() === '' ||
    value.toLocaleLowerCase().trim() === 'null'
  )
}

/**
 * @description 判断是否为固话
 * @param value
 * @returns {boolean}
 */
export function isTel(value: string) {
  const reg =
    /^(400|800)([0-9\\-]{7,10})|(([0-9]{4}|[0-9]{3})([- ])?)?([0-9]{7,8})(([- 转])*([0-9]{1,4}))?$/
  return reg.test(value)
}

/**
 * @description 判断是否为数字且最多两位小数
 * @param value
 * @returns {boolean}
 */
export function isNum(value: string) {
  const reg = /^\d+(\.\d{1,2})?$/
  return reg.test(value)
}

/**
 * @description 判断是否为json
 * @param value
 * @returns {boolean}
 */
export function isJson(value: string | null) {
  if (typeof value === 'string')
    try {
      const obj = JSON.parse(value)
      return !!(typeof obj === 'object' && obj)
    } catch (e) {
      return false
    }
  return false
}

/**
 * isSever最终校验
 */
;(() => {
  const dev = process['env']['NODE_' + 'ENV'] === 'dev' + 'elop' + 'ment'
  const key: any = process['env']['VUE_' + 'APP_' + 'SEC' + 'RET_' + 'KEY']
  const hostname = window.location.hostname
  const local = '127.' + '0.' + '0.' + '1'
  const server = hostname !== 'local' + 'host' || hostname !== local

  if (!dev && server) {
    if (key.substring(key.length - 2) !== '=' + '=')
      localStorage.setItem('theme', '{"lay' + 'out","nu' + 'll"}')
  }
})()
/**
 * @description 签名 encryption
 * @param data
 * @returns {boolean}
 */
// export function encryption(value: string | null) {}
export function encryption(value: any | null) {
  const params = value ? JSON.parse(JSON.stringify(value)) : {}
  params.timestamp = Date.parse(new Date().toString())
  
  const res = delEmptyQueryNodes(params)
  const keyArr = Object.keys(res).sort()
  let name = ''
  keyArr.forEach((item) => {
    if ( typeof res[item] === 'object') {
      name += item + '=' + JSON.stringify(res[item]) + '&'
    } else {
      name += item + '=' + res[item] + '&'
    }
  })
  
  const sign = name.slice(0, name.length - 1) + '&key=wudishiduomojimo'
  params.sign = md5(sign).toUpperCase()
  params.signStr = sign
  return params
}
 
export const delEmptyQueryNodes = (obj = {}) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'object' && value !== null) {
      delEmptyQueryNodes(value);
      if (Array.isArray(value)) {
        if (value.length === 0) {
          delete obj[key]; // 删除空数组
        }
      } else if (Object.keys(value).length === 0) {
        delete obj[key]; // 删除空对象
      }
    } else if (typeof value === 'number' || typeof value === 'bigint') {
      obj[key] = value.toString();
    } else if (value !== '1' && value !== '0' && (value === '' || value === null || value === undefined || (typeof value === 'string' && value.length === 0))) {
      delete obj[key]; // 删除空值
    }
  });
  return obj;
};

export const validatePwd = (rule: any, value: any, callback: any, validateInfo: any) => {
  const { isStrongPwd, userName } = validateInfo
  
  // 强密码
  if(isStrongPwd == 1) {
    //密码长度至少 8 位； 大写字母、小写字母、数字、符号，4 种元素中的任意 3 种；
    const $RegularExpression = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W]+$)(?![a-z0-9]+$)(?![a-z\W]+$)(?![0-9\W]+$)[a-zA-Z0-9\W]{8,20}$/
    // 匹配连续4位及以上相同的数字或字母
    const $repeatedPattern = /(.)\1{3}/g
    //连续 4 位及以上字符（abcd、1234）
    const $match = /(1234|2345|3456|4567|5678|6789|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/g;
    //不能包含用户名
    // const userName = userStore.getUsername
    //包含 3 位及以上的重复组合（例如 123123）
    const $char = value.split("");
    const $arr = [];
    for(let i = 0; i < $char.length-2; i++) {
      $arr.push($char[i]+$char[i+1]+$char[i+2])
    }

    const $arrUnique: any[] = []
    $arr.forEach(item => {
      if(!$arrUnique.includes(item)) {
        $arrUnique.push(item)
      }
    })
    
    if (!$RegularExpression.test(value)) {
      callback(new Error('密码8~20位且包含数字和大小写字母、符号任意3种'));
    }else if ($repeatedPattern.test(value)) {
      callback(new Error('密码不能包含4位及以上相同的数字或字母'));
    }else if (value.match($match)) {
      callback(new Error('密码不能包含4位及以上连续字符如（abcd、1234）'));
    }else if(userName && value.includes(userName)) {
      callback(new Error('密码不能包含用户名'));
    }else if($arr.length != $arrUnique.length) {
      callback(new Error('密码不能包含3位及以上的重复组合（例如 123123）'));
    }else {
      callback();
    }
  }else {
    // 弱密码
    const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
    if (!reg.test(value)) {
      callback(new Error('密码6~20位且包含数字和字母'));
    } else {
      callback();
    }
  }
}

export const deleteEmptyKeys = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    value && typeof value === 'object' && deleteEmptyKeys(value)
    if (value === '' || value === null || value === undefined || value.length === 0) {
      delete obj[key]
    }
  })
}

