
export const requiredFields = [
  {
    fields: 'name',
    message: '请输入姓名'
  }
]

export function getSexName(sex) {
  // 1男 2女 0保密
  return {
    1: '男',
    2: '女',
    0: '保密'
  }[sex]
}

