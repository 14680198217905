import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_input = _resolveComponent("el-input");
  const _component_ElInputNumber = _resolveComponent("ElInputNumber");
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "formRef",
    model: _ctx.form,
    rules: _ctx.rules,
    "label-width": "80px",
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 24
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "患者类型",
          prop: "type_id"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            disabled: _ctx.dataInfo != null,
            modelValue: _ctx.form.type_id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.form.type_id = $event),
            placeholder: "请选择患者类型",
            style: {
              "width": "100%"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patientType, item => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.title,
                value: item.id
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["disabled", "modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "前缀",
          prop: "is_prefix"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_group, {
            modelValue: _ctx.form.is_prefix,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.form.is_prefix = $event)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio, {
              label: 0
            }, {
              default: _withCtx(() => [_createTextVNode("无")]),
              _: 1
            }), _createVNode(_component_el_radio, {
              label: 1
            }, {
              default: _withCtx(() => [_createTextVNode("有")]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "",
          "label-width": "0",
          prop: "prefix_value"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.form.prefix_value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.form.prefix_value = $event),
            disabled: _ctx.form.is_prefix == 0,
            placeholder: "请输入前缀"
          }, null, 8, ["modelValue", "disabled"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 24
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "日期",
          prop: "date_type"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_group, {
            modelValue: _ctx.form.date_type,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.form.date_type = $event)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio, {
              label: 0
            }, {
              default: _withCtx(() => [_createTextVNode("无")]),
              _: 1
            }), _createVNode(_component_el_radio, {
              label: 1
            }, {
              default: _withCtx(() => [_createTextVNode("年")]),
              _: 1
            }), _createVNode(_component_el_radio, {
              label: 2
            }, {
              default: _withCtx(() => [_createTextVNode("年月")]),
              _: 1
            }), _createVNode(_component_el_radio, {
              label: 3
            }, {
              default: _withCtx(() => [_createTextVNode("年月日")]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 24
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "流水号",
          prop: "serial_number"
        }, {
          default: _withCtx(() => [_createVNode(_component_ElInputNumber, {
            modelValue: _ctx.form.serial_number,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.form.serial_number = $event),
            placeholder: "",
            min: 2,
            max: 8
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 24
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "起始值",
          prop: "start_value"
        }, {
          default: _withCtx(() => [_createVNode(_component_ElInputNumber, {
            modelValue: _ctx.form.start_value,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.form.start_value = $event),
            placeholder: "",
            min: 10,
            max: 99999999
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 24
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "",
          prop: "is_reset"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_checkbox, {
            modelValue: _ctx.form.is_reset,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.form.is_reset = $event),
            "true-label": 1,
            "false-label": 0,
            label: "每个周期流水号清零",
            size: "large"
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        class: "text-btn",
        type: "primary",
        onClick: _ctx.handleAddData
      }, {
        default: _withCtx(() => [_createTextVNode(" 保存 ")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        class: "text-btn",
        onClick: _ctx.handleCancel
      }, {
        default: _withCtx(() => [_createTextVNode("取消")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]);
}