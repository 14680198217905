import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 2
};
const _hoisted_4 = {
  key: 3
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_el_table = _resolveComponent("el-table");
  const _component_AddForm = _resolveComponent("AddForm");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_createVNode(_component_el_button, {
    onClick: _ctx.addCaseNORules,
    type: "primary"
  }, {
    default: _withCtx(() => [_createTextVNode("新增")]),
    _: 1
  }, 8, ["onClick"])]), _createVNode(_component_el_table, {
    ref: "tableSortRef",
    border: true,
    data: _ctx.table.data,
    "row-class-name": _ctx.tableRowClassName
  }, {
    empty: _withCtx(() => [_createVNode(_component_el_empty, {
      class: "vab-data-empty",
      description: "暂无数据"
    })]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.column, (item, index) => {
      return _openBlock(), _createBlock(_component_el_table_column, {
        key: index,
        align: item.align || 'center',
        label: item.label,
        "min-width": item.minWidth,
        prop: item.prop,
        type: item.type,
        width: item.width,
        fixed: item.fixed || false
      }, {
        default: _withCtx(({
          row
        }) => [item.label == '操作' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_button, {
          text: "",
          type: "primary",
          onClick: $event => _ctx.handleEdit(row)
        }, {
          default: _withCtx(() => [_createTextVNode(" 编辑 ")]),
          _: 2
        }, 1032, ["onClick"]), ![1, 2].includes(row.id) ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          text: "",
          type: "primary",
          onClick: $event => _ctx.handleDelete(row)
        }, {
          default: _withCtx(() => [_createTextVNode(" 删除 ")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true)])) : item.label == '日期' ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.formatDateType(row.date_type)), 1)) : item.label == '流水号' ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(row[item.prop]) + "位 ", 1)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(row[item.prop]), 1))]),
        _: 2
      }, 1032, ["align", "label", "min-width", "prop", "type", "width", "fixed"]);
    }), 128))]),
    _: 1
  }, 8, ["data", "row-class-name"]), _createVNode(_component_el_dialog, {
    modelValue: _ctx.showCaseNORules,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.showCaseNORules = $event),
    "align-center": "",
    title: "病历号规则设置",
    width: "500px",
    height: "800px",
    "destroy-on-close": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    modal: false
  }, {
    default: _withCtx(() => [_createVNode(_component_AddForm, {
      onHandleAddCallback: _ctx.handleAddCallback,
      dataInfo: _ctx.dataInfo,
      rulesArray: _ctx.rulesArray
    }, null, 8, ["onHandleAddCallback", "dataInfo", "rulesArray"])]),
    _: 1
  }, 8, ["modelValue"])]);
}