import { __awaiter, __generator } from "tslib";
/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { useAclStore } from './acl';
import { useTabsStore } from './tabs';
import { useRoutesStore } from './routes';
import { useSettingsStore } from './settings';
import { login } from '@/api/user';
import { getToken, removeToken, setToken } from '@/utils/token';
import { setItem, getItem, removeItem } from '@/utils/storage';
import { resetRouter } from '@/router';
import { gp } from '@gp';
export var useUserStore = defineStore('user', {
  state: function () {
    return {
      token: getToken(),
      username: '',
      avatar: 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif',
      sId: getItem('sId'),
      configInfo: {}
    };
  },
  getters: {
    getToken: function (state) {
      return state.token;
    },
    getUsername: function (state) {
      return state.username;
    },
    getAvatar: function (state) {
      return state.avatar;
    },
    getSId: function (state) {
      return state.sId;
    },
    getConfigInfo: function (state) {
      return state.configInfo;
    }
  },
  actions: {
    /**
     * @description 设置sId
     * @param {*} sId
     */
    setSId: function (sId) {
      this.sId = sId;
      setItem('sId', sId);
    },
    /**
     * @description 设置token
     * @param {*} token
     */
    setToken: function (token) {
      this.token = token;
      setToken(token);
    },
    /**
     * @description 设置用户名
     * @param {*} username
     */
    setUsername: function (username) {
      this.username = username;
      setItem('username', username);
    },
    /**
     * @description 设置头像
     * @param {*} avatar
     */
    setAvatar: function (avatar) {
      this.avatar = avatar;
    },
    /**
     * @description 登录拦截放行时，设置虚拟角色
     */
    setVirtualRoles: function () {
      var aclStore = useAclStore();
      aclStore.setFull(true);
      this.setUsername('admin(未开启登录拦截)');
      this.setAvatar('https://i.gtimg.cn/club/item/face/img/2/15922_100.gif');
    },
    /**
     * @description 设置token并发送提醒
     */
    afterLogin: function () {
      var settingsStore = useSettingsStore();
      var hour = new Date().getHours();
      var thisTime = hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好';
      gp.$baseNotify("\u6B22\u8FCE\u767B\u5F55".concat(settingsStore.title), "".concat(thisTime, "\uFF01"));
    },
    /**
     * @description 登录
     * @param {*} userInfo
     */
    login: function (userInfo) {
      return __awaiter(this, void 0, void 0, function () {
        var data, err;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, login(userInfo)];
            case 1:
              data = _a.sent().data;
              if (data.user_name) {
                this.setUsername(data.user_name);
                this.afterLogin();
              } else {
                err = "\u767B\u5F55\u63A5\u53E3\u5F02\u5E38...";
                gp.$baseMessage(err, 'error', 'vab-hey-message-error');
                throw err;
              }
              localStorage.setItem('userinfoObg', JSON.stringify(data));
              return [2 /*return*/];
          }
        });
      });
    },

    /**
     * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
     * @returns
     */
    getUserInfo: function () {
      // const obg: any = localStorage.getItem('userinfoObg')
      // console.log(obg, 33444)
      // if (obg.user_name) this.setUsername(obg.user_name)
      // console.log(obg.user_name, 444)
    },
    /**
     * @description 退出登录
     */
    logout: function () {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, this.resetAll()
              // 解决横向布局退出登录显示不全的bug
              ];

            case 1:
              _a.sent();
              // 解决横向布局退出登录显示不全的bug
              location.reload();
              return [2 /*return*/];
          }
        });
      });
    },

    /**
     * @description 重置token、roles、permission、router、tabsBar等
     */
    resetAll: function () {
      return __awaiter(this, void 0, void 0, function () {
        var aclStore, routesStore, tabsStore;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              this.setSId('');
              this.setToken('');
              this.setUsername('');
              this.setAvatar('https://i.gtimg.cn/club/item/face/img/2/15922_100.gif');
              aclStore = useAclStore();
              routesStore = useRoutesStore();
              tabsStore = useTabsStore();
              aclStore.setPermission([]);
              aclStore.setFull(false);
              aclStore.setRole([]);
              tabsStore.delAllVisitedRoutes();
              routesStore.clearRoutes();
              return [4 /*yield*/, resetRouter()];
            case 1:
              _a.sent();
              removeToken();
              removeItem('sId');
              return [2 /*return*/];
          }
        });
      });
    },

    // 店铺配置
    setConfigInfo: function (configInfo) {
      this.configInfo = configInfo;
    }
  }
});