import { defineComponent } from 'vue';
import { staticBase } from '@/config/setting.config';
import { encryption } from '@/utils/validate';
import { getPatientList } from '@/api/patient';
export default defineComponent({
  props: {
    keywords: {
      type: String,
      default: ''
    }
  },
  watch: {
    keywords: {
      handler(newValue, oldValue) {
        this.page = 1;
        this.patientList = [];
        this.getList();
      }
    }
  },
  data() {
    return {
      staticBase,
      page: 1,
      limit: 6,
      patientList: [],
      loading: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleinfiniteScroll() {
      if (this.loading) {
        return;
      }
      this.page += 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      const {
        data
      } = await getPatientList(encryption({
        keyword: this.keywords,
        page: this.page,
        limit: this.limit
      }));
      if (this.page == 1) {
        this.patientList = data?.list || [];
      } else {
        this.patientList = this.patientList.concat(data?.list || []);
      }
      this.loading = false;
    },
    handleChoosePatient(item) {
      this.$emit('handleChoosePatient', item);
    }
  }
});