import { defineComponent } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message-box/style/index';
import 'element-plus/es/components/message/style/index';
import BasicComp from '@/views/Basic.vue';
import { encryption } from '@/utils/validate';
import { getCaseRulesList, delCaseRules } from '@/api/patient';
import AddForm from './add.vue';
export default defineComponent({
  name: 'caseNORules',
  extends: BasicComp,
  components: {
    AddForm
  },
  data() {
    return {
      table: {
        data: [],
        column: [{
          label: '患者类型',
          prop: 'type_name',
          width: 100
        }, {
          label: '前缀',
          prop: 'prefix_value',
          width: 100
        }, {
          label: '日期',
          prop: 'date_type',
          width: 100
        }, {
          label: '流水号',
          prop: 'serial_number',
          width: 100
        }, {
          label: '操作',
          prop: ''
        }]
      },
      showCaseNORules: false,
      dataInfo: null,
      rulesArray: []
    };
  },
  created() {
    this.getCaseRulesList();
  },
  methods: {
    formatDateType(date_type) {
      return {
        0: '无',
        1: '年',
        2: '年月',
        3: '年月日'
      }[date_type];
    },
    async getCaseRulesList() {
      const {
        code,
        data
      } = await getCaseRulesList(encryption({}));
      this.table.data = data || [];
      this.rulesArray = this.table.data?.map(item => item.type_name);
    },
    addCaseNORules() {
      this.dataInfo = null;
      this.showCaseNORules = true;
    },
    handleAddCallback() {
      this.showCaseNORules = false;
      this.getCaseRulesList();
    },
    handleEdit(row) {
      this.dataInfo = row;
      this.showCaseNORules = true;
    },
    async handleDelete(row) {
      ElMessageBox.confirm(this.delConfirmMsg, null, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {
          code,
          data
        } = await delCaseRules(encryption({
          id: row.id
        }));
        if (code == 200) {
          ElMessage({
            message: this.delSuccessMsg,
            type: 'success'
          });
          this.getCaseRulesList();
        }
      });
    }
  }
});