import request from '@/utils/request'
// 获取店铺唯一编码
export async function getstorecode(data: any) {
  return request({
    url: '/interfaces/v1/login/getstorecode',
    method: 'get',
    params: data,
  })
}
// 登录接口
export async function login(data: any) {
  return request({
    url: '/interfaces/v1/login/index',
    method: 'post',
    data,
  })
}

export function register(data: any) {
  return request({
    url: '/register',
    method: 'post',
    data,
  })
}

// 修改密码
export function updatePassword(data: any) {
  return request({
    url: '/interfaces/v1/staff/editPassword',
    method: 'post',
    data,
  })
}


