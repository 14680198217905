import { ref } from 'vue';
import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/index';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { encryption, validatePwd } from '@/utils/validate';
import { updatePassword } from '@/api/user';
import { useUserStore } from '@/store/modules/user';
export default {
  __name: 'editPwd',
  props: ['noClose'],
  emits: ['handleCancel'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const userStore = useUserStore();
    const configInfo = userStore.configInfo;
    const userName = userStore.getUsername;

    // 强密码配置
    const pwdHolderObj = {
      0: '密码6~20位且包含数字和字母',
      1: '密码8~20位且包含数字和大小写字母、符号'
    };
    const isStrongPwd = configInfo.is_strong_password_enabled;
    const pwdHolder = ref(pwdHolderObj[isStrongPwd]);
    const formRef = ref();
    const checkPwd = (rule, value, callback) => {
      if (value) {
        const userName = userStore.getUsername;
        validatePwd(rule, value, callback, {
          isStrongPwd,
          userName
        });
        // // 强密码
        // if(isStrongPwd == 1) {
        //   //密码长度至少 8 位； 大写字母、小写字母、数字、符号，4 种元素中的任意 3 种；
        //   const $RegularExpression = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W]+$)(?![a-z0-9]+$)(?![a-z\W]+$)(?![0-9\W]+$)[a-zA-Z0-9\W]{8,20}$/
        //   // 匹配连续4位及以上相同的数字或字母
        //   const $repeatedPattern = /(.)\1{3}/g
        //   //连续 4 位及以上字符（abcd、1234）
        //   const $match = /(1234|2345|3456|4567|5678|6789|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/g;
        //   //不能包含用户名
        //   const userName = userStore.getUsername
        //   //包含 3 位及以上的重复组合（例如 123123）
        //   const $char = value.split("");
        //   const $arr = [];
        //   for(let i = 0; i < $char.length-2; i++) {
        //     $arr.push($char[i]+$char[i+1]+$char[i+2])
        //   }

        //   const $arrUnique = []
        //   $arr.forEach(item => {
        //     if(!$arrUnique.includes(item)) {
        //       $arrUnique.push(item)
        //     }
        //   })

        //   if (!$RegularExpression.test(value)) {
        //     callback(new Error('密码8~20位且包含数字和大小写字母、符号任意3种'));
        //   }else if ($repeatedPattern.test(value)) {
        //     callback(new Error('密码不能包含4位及以上相同的数字或字母'));
        //   }else if (value.match($match)) {
        //     callback(new Error('密码不能包含4位及以上连续字符如（abcd、1234）'));
        //   }else if(userName && value.includes(userName)) {
        //     callback(new Error('密码不能包含用户名'));
        //   }else if($arr.length != $arrUnique.length) {
        //     callback(new Error('密码不能包含3位及以上的重复组合（例如 123123）'));
        //   }else {
        //     callback();
        //   }
        // }else {
        //   // 弱密码
        //   const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
        //   if (!reg.test(value)) {
        //     callback(new Error('密码6~20位且包含数字和字母'));
        //   } else {
        //     callback();
        //   }
        // }
      }
    };

    const checkConfirmPwd = (rule, value, callback) => {
      if (value !== pwdInfo.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const pwdInfo = ref({
      password: '',
      newpassword: '',
      oldpassword: ''
    });
    const rules = ref({
      password: [{
        required: true,
        message: '请输入原密码',
        trigger: 'blur'
      }],
      newpassword: [{
        required: true,
        message: '请输入新密码',
        trigger: ['blur', 'change']
      }, {
        validator: checkPwd,
        trigger: ['blur', 'change']
      }],
      oldpassword: [{
        required: true,
        message: '请输入确认密码',
        trigger: ['blur', 'change']
      }, {
        validator: checkConfirmPwd,
        trigger: ['blur', 'change']
      }]
    });
    const handleSave = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          try {
            const {
              code,
              msg,
              data
            } = await updatePassword(encryption({
              old_password: pwdInfo?.value?.password,
              new_password: pwdInfo?.value?.newpassword
            }));
            if (code == 200) {
              ElMessage({
                type: 'success',
                message: '修改密码成功'
              });
              emit('handleCancel');
            }
          } finally {}
        }
      });
    };
    const handleCancel = () => {
      formRef.value.resetFields();
      emit('handleCancel');
    };
    return (_ctx, _cache) => {
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createBlock(_component_el_form, {
        ref_key: "formRef",
        ref: formRef,
        "label-position": "left",
        "label-width": "80px",
        model: _unref(pwdInfo),
        rules: _unref(rules),
        style: {
          "max-width": "460px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "登录账号"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(userName)), 1)]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "原密码",
          prop: "password"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(pwdInfo).password,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(pwdInfo).password = $event),
            placeholder: "请输入原密码",
            type: "password"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "新密码",
          prop: "newpassword"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(pwdInfo).newpassword,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(pwdInfo).newpassword = $event),
            placeholder: _unref(pwdHolder),
            type: "password"
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "确认密码",
          prop: "oldpassword"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(pwdInfo).oldpassword,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(pwdInfo).oldpassword = $event),
            placeholder: "请输入新密码",
            type: "password"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [!__props.noClose ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            onClick: _cache[3] || (_cache[3] = $event => handleCancel())
          }, {
            default: _withCtx(() => [_createTextVNode("取消")]),
            _: 1
          })) : _createCommentVNode("", true), _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[4] || (_cache[4] = $event => handleSave())
          }, {
            default: _withCtx(() => [_createTextVNode("确定")]),
            _: 1
          })])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"]);
    };
  }
};