import { __awaiter, __generator } from "tslib";
import { getBtnList } from '@/api/menu';
export var useAclStore = defineStore('acl', {
  state: function () {
    return {
      admin: false,
      role: [],
      permission: []
    };
  },
  getters: {
    getAdmin: function (state) {
      return state.admin;
    },
    getRole: function (state) {
      return state.role;
    },
    getPermission: function (state) {
      return state.permission;
    }
  },
  actions: {
    setFull: function (admin) {
      this.admin = admin;
    },
    setRole: function (role) {
      this.role = role;
    },
    setPermission: function (permission) {
      this.permission = permission;
    },
    getPermissionsByApi: function () {
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, getBtnList()];
            case 1:
              data = _a.sent().data;
              this.setPermission(data || []);
              return [2 /*return*/];
          }
        });
      });
    }
  }
});