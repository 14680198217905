import { ref } from 'vue';
import { ElMessageBox } from 'element-plus/es';
import 'element-plus/es/components/message-box/style/index';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, isRef as _isRef, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { watch } from 'vue';
import EditPwd from '@/components/editPwd.vue';
import { encryption } from '@/utils/validate';
import { getShopConfigInfo } from '@/api/publicApi';
import { useUserStore } from '@/store/modules/user';
export default {
  __name: 'App',
  setup(__props) {
    const noClose = ref(false);
    const revisePassword = ref(false);
    watch(() => useUserStore().token, (newVal, oldVal) => {
      if (newVal) {
        configInfo();
      }
    });

    // 查询是否弱密码
    if (useUserStore().token) {
      configInfo();
    }
    async function configInfo() {
      const {
        code,
        msg,
        data
      } = await getShopConfigInfo(encryption({}));
      const userStore = useUserStore();
      userStore.setConfigInfo(data);
      if ([1, 2].includes(data?.type)) {
        noClose.value = true;
        ElMessageBox.confirm(msg, '', {
          confirmButtonText: '确定',
          type: 'warning',
          'show-close': false,
          showCancelButton: false,
          'close-on-click-modal': false,
          'close-on-press-escape': false,
          'close-on-hash-change': false
        }).then(() => {
          revisePassword.value = true;
        }).catch(() => {});
      } else {
        noClose.value = false;
      }
    }
    const handleCancel = () => {
      revisePassword.value = false;
    };
    return (_ctx, _cache) => {
      const _component_vab_app = _resolveComponent("vab-app");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_vab_app), _createVNode(_component_el_dialog, {
        modelValue: _unref(revisePassword),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(revisePassword) ? revisePassword.value = $event : null),
        "align-center": "",
        title: "修改密码",
        width: "30%",
        "destroy-on-close": "true",
        "show-close": !_unref(noClose),
        "close-on-click-modal": !_unref(noClose),
        "close-on-press-escape": !_unref(noClose)
      }, {
        default: _withCtx(() => [_createVNode(EditPwd, {
          noClose: true,
          onHandleCancel: handleCancel
        })]),
        _: 1
      }, 8, ["modelValue", "show-close", "close-on-click-modal", "close-on-press-escape"])], 64);
    };
  }
};