import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/index';
import BasicComp from '@/views/Basic.vue';
import { getBasicItem, addNationality, editNationality } from '@/api/patient';
import { encryption } from '@/utils/validate';
import _ from 'lodash';
export default defineComponent({
  extends: BasicComp,
  props: {
    dataInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {},
      rules: {
        title: [{
          required: true,
          trigger: 'blur',
          message: '请输入名称'
        }]
      }
    };
  },
  created() {
    if (this.dataInfo) {
      this.form = {
        ...this.dataInfo
      };
    }
  },
  methods: {
    handleAddData() {
      let form = _.cloneDeep(this.form);
      this.$refs['formRef'].validate(async (valid, fields) => {
        if (valid) {
          if (form.id) {
            const {
              code,
              msg
            } = await editNationality(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '修改成功',
                type: 'success'
              });
              this.$emit('handleAddCallback', 'save');
            }
          } else {
            const {
              code,
              msg
            } = await addNationality(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '新建成功',
                type: 'success'
              });
              this.$emit('handleAddCallback', 'save');
            }
          }
        }
      });
    },
    handleCancel() {
      this.$emit('handleCancelCallback');
    }
  }
});