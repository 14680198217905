import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/index';
import "core-js/modules/es.array.push.js";
import { getUserList, getarea } from '@/api/publicApi';
import { getBasicItem, getCaseNO, getNationalityList, getPatientOriginTree, getPatientOriginList, getPatientTagList, addPatient, editPatient } from '@/api/patient';
import { encryption } from '@/utils/validate';
import { IDType } from '@/utils/constant';
import caseNORules from './caseNORules/index.vue';
import nationality from './nationality/index.vue';
import mobileType from './mobileType/index.vue';
import telPhoneType from './telPhoneType/index.vue';
import patientOrigin from './patientOrigin/index.vue';
import relationship from './relationship/index.vue';
import { fileExt, fileUpload } from '@/utils/upload';
export default defineComponent({
  name: 'patientAdd',
  components: {
    caseNORules,
    nationality,
    mobileType,
    telPhoneType,
    patientOrigin,
    relationship
  },
  watch: {
    'form.type': {
      handler(newValue, oldValue) {
        this.getCaseNO();
      }
    },
    'form.province': {
      handler(newValue, oldValue) {
        this.form.city = '';
        this.cityList = [];
        this.getCityList();
      }
    },
    'form.city': {
      handler(newValue, oldValue) {
        this.form.area = '';
        this.areaList = [];
        this.getAreaList();
      }
    },
    'form.patient_one': {
      handler(newValue, oldValue) {
        this.form.patient_two = '';
        this.form.patient_three = '';
        this.patientOrigin_two = this.patientOrigin.find(item => item.id == newValue)?._children;
      }
    },
    'form.patient_two': {
      handler(newValue, oldValue) {
        this.form.patient_three = '';
        this.patientOrigin_three = this.patientOrigin_two.find(item => item.id == newValue)?._children;
      }
    }
  },
  created() {
    this.handleFieldsRules();
    this.getPatientType();
    this.getNationalityList();
    this.getProvinceList();
    this.getMobileType();
    this.getTelPhoneType();
    this.getPatientOriginTree();
    this.getPatientOriginList();
    this.getPatientTagList();
    this.getDoctorList();
    this.getConsultantList();
    this.getUserList();
    this.getNetConsultantList();
    this.getCustomerServiceList();
    this.getChiefComplaintList();
    this.form.sex = 0;
  },
  props: {
    requiredFieldsList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      form: {
        mobileList: [{}],
        phoneNumberList: [{}]
      },
      rules: {
        mobileType: [{
          required: true,
          trigger: 'change',
          message: '请选择类型'
        }],
        mobileNumber: [{
          required: true,
          trigger: 'change',
          message: '请输入手机号'
        }],
        photoNumber: [{
          required: true,
          trigger: 'change',
          message: '请输入电话号'
        }]
      },
      patientType: [],
      disabledCaseNO: true,
      showCaseNORules: false,
      IDType,
      showNationality: false,
      nationalityList: [],
      educationList: ['小学', '初中', '高中', '专科', '本科', '研究生', '博士', '其他'],
      provinceList: [],
      cityList: [],
      areaList: [],
      mobileType: [],
      showMobileType: false,
      telPhoneType: [],
      showTelPhoneType: false,
      patientOrigin: [],
      patientOrigin_two: [],
      patientOrigin_three: [],
      patientOriginList: [],
      showPatientOrigin: false,
      patientTagList: [],
      doctorList: [],
      consultantList: [],
      userList: [],
      netConsultantList: [],
      customerServiceList: [],
      itemsList: ['定期复诊', '口腔检查', '牙体', '口外', '修复', '种植', '正畸', '牙周'],
      chiefComplaintList: []
    };
  },
  methods: {
    getShowFieldFlag(field) {
      const fieldInfo = this.requiredFieldsList.find(item => {
        return item.fields == field;
      });
      if (fieldInfo) {
        return true;
      }
      return false;
    },
    handleFieldsRules() {
      this.requiredFieldsList.forEach(fieldItem => {
        let flag = false;
        if (this.form.type == 2) {
          flag = fieldItem?.normal_is_required == 1 ? true : false;
        } else {
          flag = fieldItem?.temporary_is_required == 1 ? true : false;
        }
        if (flag) {
          this.rules[fieldItem.fields] = [{
            required: true,
            trigger: 'change',
            message: '请输入' + fieldItem.title
          }];

          // 特殊处理
          if (fieldItem.fields == 'address') {
            this.rules['province'] = [{
              required: true,
              trigger: ['blur', 'change'],
              message: '请选择省'
            }, {
              validator: this.validatorPrivince,
              trigger: ['blur', 'change']
            }];
          }
          if (fieldItem.fields == 'patient') {
            this.rules['patient_one'] = [{
              required: true,
              trigger: ['blur', 'change'],
              message: '请选择一级来源'
            }, {
              validator: this.validatorPatient,
              trigger: ['blur', 'change']
            }];
          }
          if (fieldItem.fields == 'certificate') {
            this.rules['certificate_type'] = [{
              required: true,
              trigger: ['blur', 'change'],
              message: '请选择证件类型'
            }];
          }
        }
      });
    },
    validatorPrivince(rule, value, callback) {
      if (value) {
        if (!this.form.city) {
          callback(new Error('请选择市'));
        } else if (!this.form.area) {
          callback(new Error('请选择区'));
        } else {
          callback();
        }
      } else {
        callback(new Error('请选择省'));
      }
    },
    validatorPatient(rule, value, callback) {
      if (value) {
        if (!this.form.patient_two) {
          callback(new Error('请选择二级来源'));
        } else if (!this.form.patient_three) {
          callback(new Error('请选择三级来源'));
        } else {
          callback();
        }
      } else {
        callback(new Error('请选择一级来源'));
      }
    },
    async getPatientType() {
      const {
        code,
        data
      } = await getBasicItem(encryption({
        type: '患者类型'
      }));
      this.patientType = data || [];
      this.form.type = this.patientType?.length ? this.patientType[0].id : '';
      this.getCaseNO();
    },
    async getCaseNO() {
      const {
        code,
        data
      } = await getCaseNO(encryption({
        type_id: this.form.type
      }));
      this.form.card_number = data;
    },
    async getNationalityList() {
      const {
        code,
        data
      } = await getNationalityList(encryption({}));
      this.nationalityList = data || [];
    },
    async getProvinceList() {
      const {
        code,
        data
      } = await getarea(encryption({}));
      this.provinceList = data || [];
    },
    async getCityList() {
      const {
        code,
        data
      } = await getarea(this.form.province);
      this.cityList = data || [];
    },
    async getAreaList() {
      const {
        code,
        data
      } = await getarea(this.form.city);
      this.areaList = data || [];
    },
    handleShowMobileType() {
      this.showMobileType = true;
    },
    async getMobileType() {
      const {
        code,
        data
      } = await getBasicItem(encryption({
        type: '手机号码属性'
      }));
      this.mobileType = data || [];
    },
    addMobile() {
      if (this.form.mobileList?.length >= 3) {
        ElMessage({
          message: '同个患者最多添加三个手机号码！',
          type: 'error'
        });
        return;
      }
      this.form.mobileList.push({});
    },
    delMobile(index) {
      this.form.mobileList.splice(index, 1);
    },
    handleShowTelPhoneType() {
      this.showTelPhoneType = true;
    },
    async getTelPhoneType() {
      const {
        code,
        data
      } = await getBasicItem(encryption({
        type: '电话号码属性'
      }));
      this.telPhoneType = data || [];
    },
    addTelPhone() {
      if (this.form.phoneNumberList?.length >= 3) {
        ElMessage({
          message: '同个患者最多添加三个电话号码！',
          type: 'error'
        });
        return;
      }
      this.form.phoneNumberList.push({});
    },
    delTelPhone(index) {
      this.form.phoneNumberList.splice(index, 1);
    },
    handleShowPatientOrigin() {
      this.showPatientOrigin = true;
    },
    async getPatientOriginTree() {
      const {
        code,
        data
      } = await getPatientOriginTree(encryption({}));
      this.patientOrigin = data || [];
    },
    async getPatientOriginList() {
      const {
        code,
        data
      } = await getPatientOriginList(encryption({}));
      this.patientOriginList = data || [];
    },
    async getPatientTagList() {
      const {
        code,
        data
      } = await getPatientTagList(encryption({}));
      this.patientTagList = data || [];
    },
    async getDoctorList() {
      const {
        code,
        data
      } = await getUserList(encryption({
        position_id: '2'
      }));
      this.doctorList = data || [];
    },
    async getConsultantList() {
      const {
        code,
        data
      } = await getUserList(encryption({
        position_id: '9'
      }));
      this.consultantList = data || [];
    },
    async getUserList() {
      const {
        code,
        data
      } = await getUserList(encryption({
        // position_id: '9'
      }));
      this.userList = data || [];
    },
    async getNetConsultantList() {
      const {
        code,
        data
      } = await getUserList(encryption({
        position_id: '11'
      }));
      this.netConsultantList = data || [];
    },
    async getCustomerServiceList() {
      const {
        code,
        data
      } = await getUserList(encryption({
        position_id: '10'
      }));
      this.customerServiceList = data || [];
    },
    getExclusiveList() {
      const list = this.doctorList.concat(this.consultantList);
      return list;
    },
    handleAgeByBirthday(val) {
      const date = new Date();
      const year = date.getFullYear();
      this.form.age = year - val.split('-')[0] + 1;
    },
    handleBirthdayByAge(val) {
      const date = new Date();
      const year = date.getFullYear();
      this.form.birthday = year - val + 1 + '-01-01';
    },
    handleAddData() {
      let form = _.cloneDeep(this.form);
      form.tag_ids = form.tag_ids?.length ? form.tag_ids.join(',') : '';
      form.consulting_items = form.consulting_items?.length ? form.consulting_items.join(',') : '';
      this.handleEmptyArray(form.mobileList);
      this.handleEmptyArray(form.phoneNumberList);
      this.$refs['formRef'].validate(async (valid, fields) => {
        if (valid) {
          if (form.id) {
            const {
              code,
              msg
            } = await editPatient(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '修改成功',
                type: 'success'
              });
              this.form = {}, this.$emit('handleAddPatient', true);
            }
          } else {
            const {
              code,
              msg,
              id
            } = await addPatient(encryption(form));
            if (code == 200) {
              ElMessage({
                message: '新建成功',
                type: 'success'
              });
              this.$emit('handleAddPatient', true, id);
            }
          }
        }
      });
    },
    handleCancel() {
      this.$emit('handleAddPatient', true);
    },
    handleEmptyArray(arr) {
      if (arr?.length == 1) {
        if (Object.keys(arr?.[0]).length == 0) {
          arr.splice(0, 1);
        }
      } else {
        return arr;
      }
    },
    async getChiefComplaintList() {
      const {
        code,
        data
      } = await getBasicItem(encryption({
        type: '就诊主诉'
      }));
      this.chiefComplaintList = data || [];
    },
    beforeUpload(file) {
      fileUpload(file).then(res => {
        this.form.avatar = res?.url;
      });
      return false;
    },
    deleteImages() {
      this.form.avatar = '';
    }
  }
});